import { Box, Chip, Typography } from '@mui/material'

const MessageCard = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        padding: '20px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          gap: '8px',
        }}>
        <img
          src='https://picsum.photos/200/200'
          alt='profile'
          style={{ borderRadius: '50%', width: '64px', height: '64px' }}
        />
        {/* sender */}
        <Box>
          <Typography
            component={'p'}
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              color: 'secondary.main',
              lineHeight: '1',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              // maxWidth: '225px',
              maxWidth: { xs: '225px', sm: '147px', md: '225px' },
            }}>
            Mr. Vinod Kimbahune
          </Typography>
          <Typography
            component={'p'}
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'primary.dark',
            }}>
            HOD-Computer
          </Typography>
          <Chip
            label={'Everyone'}
            variant='filled'
            size='small'
            sx={{
              fontSize: '12px',
              marginTop: '4px',
              borderRadius: '5px',
            }}
          />
        </Box>

        <Box flexGrow={'1'} />

        {/* Time */}
        <Box>
          <Typography
            component={'p'}
            sx={{
              fontSize: '12px',
              color: 'primary.dark',
              lineHeight: '1',
              fontWeight: 500,
            }}>
            12:45
          </Typography>
          <Typography
            component={'p'}
            sx={{
              fontSize: '12px',
              color: 'primary.dark',
              fontWeight: 500,
            }}>
            Today
          </Typography>
        </Box>
      </Box>

      <Typography
        component={'p'}
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          paddingTop: '12px',
          lineHeight: '1.2',
        }}>
        All Staff members are requested to to take a compulsory Leave on this
        Saturday!!
      </Typography>
    </Box>
  )
}

export default MessageCard


import dayjs from "dayjs";
import { toast } from "sonner";
import useStore from "../store/store";
import {
  OfficialDataType,
  PersonalDataType,
  PrimaryDataType,
  UserProfileType,
} from "../types/profile";
import { MonthHoliday, RawHoliday } from "../types/holidayList";
import { getMonthName, getWeekday } from "../utils/utils";
import { MissPunchFormData, MissPunchRegisterType } from "../types/missPunch";
import { LoginFormData } from "../types/login";
import { CompOffFormData } from "../types/compOff";
import { LeaveSubmitFormData } from "../types/leave";
import axios from "axios";

export const validateToken = async () => {
  const authToken = localStorage.getItem("authToken");
  const staffIdStr = localStorage.getItem("staffId");
  const instituteIdStr = localStorage.getItem("instituteId");

  if (authToken && staffIdStr && instituteIdStr) {
    try {
      const tokenValid = true;
      if (tokenValid) {
        const staffId = parseInt(staffIdStr!);
        const instituteId = parseInt(instituteIdStr!);
        const id = parseInt(instituteIdStr! + staffIdStr);

        const { login } = useStore.getState();
        login(authToken, id, instituteId, staffId);
        await getProfileInfo();

        return true;
      } else {
        throw new Error("Invalid Token");
      }
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error("Login Failed");
  }
};

export const handleAuth = async (formData: LoginFormData) => {
  try {
    const response = await axios.post(
      `/api/Authentication/token`,
      formData
      );
    if (response.status === 200) {
      const id = parseInt(formData.id);
      const staffId = parseInt(formData.id.slice(-3));
      const instituteId = parseInt(formData.id.slice(0, 2));
      const token = response.data;

      const { login } = useStore.getState();

      login(token, id, instituteId, staffId);
      localStorage.setItem("authToken", response.data);
      localStorage.setItem("instituteId", instituteId.toString());
      localStorage.setItem("staffId", staffId.toString());

      await getProfileInfo();
      return true;
    } else {
      throw new Error("Login Failed");
    }
  } catch (error) {
    toast.error("Internal Server Error");
    throw error;
  }
};

export const getProfileInfo = async () => {
  try {
    const {
      token,
      setToken,
      staffId,
      instituteId,
      setName,
      setDeptId,
      setRoleId,
      setProfileImage,
    } = useStore.getState();

    // Ensure the token is available
    if (!token) {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        setToken(authToken);
      } else {
        throw new Error("Invalid Token");
      }
    }

    // Fetch profile information
    const response = await axios.get<UserProfileType[]>(
      `/api/MyLinksStaff/Staff/${staffId}?InstituteID=${instituteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const profileData = response.data[0];

      setName(profileData.StaffName);
      setDeptId(profileData.DeptID);
      setRoleId(profileData.RoleID);
      setProfileImage(profileData.Photobyte ?? "");

      // Transform and return profile data
      const personalData: PersonalDataType = {
        ErpStaffID: profileData.ErpStaffID,
        Caste: profileData.Caste,
        Gender: profileData.Gender,
        MaritalStatus: profileData.MaritalStatus,
        BloodGroup: profileData.BloodGroup,
        PermanentAddress: profileData.PermanentAddress,
        LocalAddress: profileData.LocalAddress,
        Religion: profileData.Religion,
      };

      const officialData: OfficialDataType = {
        AadharNo: profileData.AadharNo,
        BankName: profileData.BankName,
        CurrentStatus: profileData.CurrentStatus,
        EmploymentTypeFP: profileData.EmploymentTypeFP,
        PANNo: profileData.PANNo,
        PFNo: profileData.PFNo,
        WorkCategoryTN: profileData.WorkCategoryTN,
        WorkGroup: profileData.WorkGroup,
        AccountNo: profileData.AccountNo,
      };

      const primaryData: PrimaryDataType = {
        StaffName: profileData.StaffName,
        Photobyte: profileData.Photobyte ?? "",
        EMailID: profileData.EMailID,
        CellNo: profileData.CellNo,
        Designation: profileData.Designation,
      };

      return {
        personal: personalData,
        official: officialData,
        primary: primaryData,
      };
    } else {
      throw new Error("Unable to Fetch Profile");
    }
  } catch (error) {
    toast.error("Internal Server Error");
    console.error("Profile fetch error:", error);
    throw error;
  }
};

export const getHolidayList = async (year: number) => {
  const url = `/api/MyLinksStaff/HolidayList?Year=${year}`;

  try {
    const response = await axios.get(url);
    const formatted: MonthHoliday[] = Array.from({ length: 12 }, () => ({
      monthId: 0,
      monthName: "",
      holidays: [],
    }));
    const holidays = response.data;
    holidays.forEach((holiday: RawHoliday) => {
      const [day, month, year] = holiday.HDate.split("/").map(Number);
      const monthIndex = month - 1;
      const monthName = getMonthName(month);

      formatted[monthIndex].monthId = month;
      formatted[monthIndex].monthName = monthName;
      formatted[monthIndex].holidays.push({
        day: day,
        label: holiday.Description,
        weekday: getWeekday(day, month, year),
      });
    });

    return formatted.filter((month) => month.holidays.length > 0);
  } catch (error) {
    throw new Error("Error while fetching holiday list");
  }
};

export const getCompOffRegister = async () => {
  try {
    const { staffId } = useStore.getState();
    const url = `/api/LeaveModule/compoffregister?StaffID=${staffId}`;
    return (await axios.get(url)).data;
  } catch (error) {
    console.error("Error fetching CompOffRegister:", error);
    toast("Error fetching CompOff Register");
    return null;
  }
};

export const displayCompOff = async (EntryID: string) => {
  const url = "/api/LeaveModule/displaycompoff";
  const data = { EntryID };
  return (await axios.get(url, { params: data })).data;
};

export const saveCompOff = async (formData: CompOffFormData) => {
  const url = "/api/LeaveModule/savecompoff";

  const { name, instituteId, deptId, staffId, roleId } = useStore.getState();

  const data = {
    InstituteID: instituteId,
    StaffID: staffId,
    DeptID: deptId,
    UserName: name,
    HODRole: roleId === 5,
    CompOffDate: dayjs(formData.compOffDate).toISOString(),
    Description: formData.description,
    WorkPlace: formData.workPlace,
  };
  return (await axios.post(url, data)).data;
};

export const updateCompOff = async (formData: CompOffFormData) => {
  const url = "/api/LeaveModule/updatecompoff";

  const { instituteId, deptId, staffId, roleId } = useStore.getState();

  const data = {
    InstituteID: instituteId,
    StaffID: staffId,
    DeptID: deptId,
    HODRole: roleId === 5,
    EntryID: formData.entryID,
    CompOffDate: dayjs(formData.compOffDate).toISOString(),
    Description: formData.description,
    WorkPlace: formData.workPlace,
  };
  return (await axios.post(url, data)).data;
};

export const deleteCompOff = async (EntryID: string) => {
  const url = "/api/LeaveModule/deletecompoff";
  return (await axios.delete(url, { params: { EntryID } })).data;
};

export const getMissPunchRegister = async (
  fromDate: string,
  toDate: string
) => {
  try {
    const { staffId } = useStore.getState();
    const url = `/api/LeaveModule/misspuchregister?FromDate=${fromDate}&ToDate=${toDate}&StaffID=${staffId}&RoleID=1`;
    return (await axios.get<MissPunchRegisterType[]>(url)).data;
  } catch (error) {
    console.error("Error fetching MissPunchRegister:", error);
    toast("Error fetching MissPunch Register");
    return null;
  }
};

export const displayMissPunch = async (EntryID: string) => {
  const url = "/api/LeaveModule/displaymisspunch";
  const data = { EntryID };
  return (await axios.get(url, { params: data })).data;
};

export const saveMissPunch = async (formData: MissPunchFormData) => {
  const url = "/api/LeaveModule/savemisspunch";

  const { name, instituteId, staffId, deptId } = useStore.getState();

  const data = {
    InstituteID: instituteId,
    StaffID: staffId,
    DeptID: deptId,
    UserName: name,
    MissPunchDate: dayjs(formData.missPunchDate).format("YYYY-MM-DD"),
    Reason: formData.description,
    HODRole: "true",
    InTime: formData.inTime
      ? dayjs(formData.inTime).format("YYYY-MM-DD HH:mm:ss")
      : null,
    OutTime: formData.outTime
      ? dayjs(formData.outTime).format("YYYY-MM-DD HH:mm:ss")
      : null,
    MissPunchCategory: formData.missPunchFor,
  };

  return (await axios.post(url, data)).data;
};

export const updateMissPunch = async (formData: MissPunchFormData) => {
  const url = "/api/LeaveModule/updatemisspunch";

  const { instituteId, staffId, deptId } = useStore.getState();

  const data = {
    InstituteID: instituteId,
    StaffID: staffId,
    DeptID: deptId,
    EntryID: formData.entryID,
    ApplicationDate: dayjs(formData.missPunchDate).format("MM/DD/YYYY"),
    MissPunchDate: dayjs(formData.missPunchDate).format("MM/DD/YYYY"),
    Reason: formData.description,
    HODRole: "false",
    MissPunchCategory: formData.missPunchFor,
    InTime: formData.inTime
      ? dayjs(formData.inTime).format("YYYY-MM-DD HH:mm:ss")
      : null,
    OutTime: formData.outTime
      ? dayjs(formData.outTime).format("YYYY-MM-DD HH:mm:ss")
      : null,
  };

  return (await axios.post(url, data)).data;
};

export const deleteMissPunch = async (EntryID: string) => {
  const url = "/api/LeaveModule/deletemisspunch";
  return (await axios.delete(url, { params: { EntryID } })).data;
};

export const getLeaveBalanceData = async () => {
  try {
    const url = `/api/LeaveModule/leavebalance`;
    const { instituteId, staffId } = useStore.getState();
    const params = {
      InstituteID: instituteId,
      UserID: staffId,
    };
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    console.error("Error fetching leave balance data:", error);
    toast("Error fetching leave balance data");
    return null;
  }
};

export const getCompOffCreditBalance = async () => {
  try {
    const url = `/api/LeaveModule/compoffbalance`;
    const { instituteId, staffId } = useStore.getState();
    const params = {
      InstituteID: instituteId,
      UserID: staffId,
    };
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    console.error("Error fetching CompOff credit balance:", error);
    toast("Error fetching CompOff credit balance");
    return null;
  }
};

export const getLeaveTypes = async () => {
  try {
    const url = `/api/LeaveModule/bindleavetype`;
    const { instituteId } = useStore.getState();
    const params = {
      InstituteID: instituteId,
    };
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    console.error("Error fetching leave types:", error);
    toast("Error fetching leave types");
    return null;
  }
};

export const getInchargeStaff = async (type: "dept" | "all") => {
  try {
    let url;
    let params;
    const { instituteId, staffId } = useStore.getState();

    if (type === "dept") {
      url = `/api/LeaveModule/getinchargepersondept`;
      params = {
        InstituteID: instituteId,
        UserID: staffId,
        DeptID: "14",
      };
    } else {
      url = `/api/LeaveModule/getinchargepersonall`;
      params = {
        InstituteID: instituteId,
        UserID: staffId,
      };
    }
    const { data } = await axios.get(url, { params });
    return data;
  } catch (error) {
    console.error("Error fetching incharge staff:", error);
    toast("Error fetching incharge staff");
    return null;
  }
};

export const displayLeaveData = async (leaveEntryCode: string) => {
  try {
    const { instituteId } = useStore.getState();
    const url = `/api/LeaveModule/displayleavedata?InstituteID=${instituteId}&LeaveEntryCode=${leaveEntryCode}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.error("Error displaying leave data:", error);
    toast("Error displaying leave data");
    return error;
  }
};

export const bindLeaveTypes = async () => {
  try {
    const { instituteId } = useStore.getState();
    const url = `/api/LeaveModule/bindleavetype?InstituteID=${instituteId}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.error("Error binding leave types:", error);
    toast("Error binding leave types");
    return error;
  }
};

export const deleteLeaveRecord = async (leaveEntryCode: string) => {
  try {
    const url = `/api/LeaveModule/deleteleaverecord?LeaveEntryCode=${leaveEntryCode}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (error) {
    console.error("Error deleting leave record:", error);
    toast("Error deleting leave record");
    return error;
  }
};

export const saveLeave = async (formData: LeaveSubmitFormData) => {
  const url = "/api/LeaveModule/savecompoff";

  const { instituteId, deptId, staffId, roleId } = useStore.getState();

  const data = {
    instituteID: instituteId,
    staffID: staffId,
    deptID: deptId,
    hodRole: roleId === 5 ? "true" : "false",
    showAllStaff: formData.showAllStaff || "false",
    deptStaff: formData.deptStaff,
    dayType: formData.dayType,
    leaveAddress: formData.leaveAddress,
    leaveReason: formData.leaveReason,
    totalDays: formData.totalDays,
    chkSingleDay: formData.chkSingleDay,
    chkHalfDay: formData.chkHalfDay,
    chkFirstHalf: formData.chkFirstHalf,
    chkSecondHalf: formData.chkSecondHalf,
    chkAllStaff: formData.chkAllStaff,
    chkTillHalfDay: formData.chkTillHalfDay,
    leaveType: formData.leaveType,
    leaveFromDate: dayjs(formData.leaveFromDate).toISOString(),
    leaveToDate: dayjs(formData.leaveToDate).toISOString(),
    chkCompoffID: formData.chkCompoffID,
  };

  return (await axios.post(url, data)).data;
};

export const updateLeave = async (formData: LeaveSubmitFormData) => {
  const url = "/api/LeaveModule/updatecompoff";

  const { instituteId, deptId, staffId, roleId } = useStore.getState();

  const data = {
    instituteID: instituteId,
    staffID: staffId,
    deptID: deptId,
    hodRole: roleId === 5 ? "true" : "false",
    entryID: formData.entryID,
    dayType: formData.dayType,
    leaveAddress: formData.leaveAddress,
    leaveReason: formData.leaveReason,
    totalDays: formData.totalDays,
    chkSingleDay: formData.chkSingleDay,
    chkHalfDay: formData.chkHalfDay,
    chkFirstHalf: formData.chkFirstHalf,
    chkSecondHalf: formData.chkSecondHalf,
    chkAllStaff: formData.chkAllStaff,
    chkTillHalfDay: formData.chkTillHalfDay,
    leaveType: formData.leaveType,
    leaveFromDate: dayjs(formData.leaveFromDate).toISOString(),
    leaveToDate: dayjs(formData.leaveToDate).toISOString(),
    chkCompoffID: formData.chkCompoffID,
  };

  return (await axios.post(url, data)).data;
};

import { Button } from "@mui/material";
import useStore from "../store/store";

const ChangePassword = () => {
  const { setShowDialog } = useStore();

  const handleClose = () => {
    setShowDialog(false);
  };

  // change password logic here

  return (
    <>
      <Button
        variant="text"
        color="primary"
        sx={{
          margin: "8px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        sx={{
          margin: "8px",
          backgroundColor: "secondary.main",
          "&:hover": {
            backgroundColor: "secondary.dark",
          },
        }}
        type="submit"
      >
        Submit
      </Button>
    </>
  );
};

export default ChangePassword;

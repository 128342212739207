import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'

const initialValue = dayjs()

export default function DateCalendarServerRequest() {
  const [highlightedDays, _setHighlightedDays] = useState([
    '2024-03-26',
    '2024-03-27',
    '2024-04-01',
    '2024-04-20',
  ])

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: string[] },
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props

    const formattedDay = day.format('YYYY-MM-DD')
    const isSelected =
      !outsideCurrentMonth && highlightedDays.includes(formattedDay)

    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          color: isSelected ? 'white' : 'inherit',
          backgroundColor: isSelected ? '#E76474' : 'transparent',
          '&:hover': {
            backgroundColor: isSelected ? '#E76474' : 'rgba(0, 0, 0, 0.04)',
          },
          '&:focus': {
            backgroundColor: isSelected ? '#E76474' : 'rgba(0, 0, 0, 0.12)',
          },
        }}
      />
    )
  }

  return (
    <DateCalendar
      readOnly
      defaultValue={initialValue}
      renderLoading={() => <DayCalendarSkeleton />}
      slots={{
        day: ServerDay,
      }}
      slotProps={{
        day: {
          highlightedDays,
        } as any,
      }}
    />
  )
}

import { Button } from "@mui/material";
import useStore from "../store/store";
import { useDeleteCompOffMutation } from "../services/mutations";

const DeleteCompOff = () => {
  const { setLoading, setShowDialog, compOffId } = useStore();

  const handleClose = () => {
    setShowDialog(false);
  };

  const { status, mutateAsync } = useDeleteCompOffMutation();

  if (status === "pending") setLoading(true);

  const onSubmit = async () => {
    await mutateAsync(compOffId);
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        sx={{
          margin: "8px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          onSubmit();
        }}
        variant="contained"
        sx={{
          margin: "8px",
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "error.dark",
          },
        }}
        type="submit"
      >
        Delete
      </Button>
    </>
  );
};

export default DeleteCompOff;

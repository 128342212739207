import { Divider, Typography, Box } from '@mui/material'

interface HeadDividerProps {
  heading: string
  variant?: 'small'
}

const HeadDivider = ({ heading, variant }: HeadDividerProps) => {
  return (
    <Box>
      <Typography
        variant={variant === 'small' ? 'h6' : 'h4'}
        sx={{
          paddingBottom: variant === 'small' ? '4px' : 1,
          fontWeight: variant === 'small' ? 500 : 500,
          color: 'primary.main',
          fontSize: variant === 'small' ? '16px' : undefined,
        }}>
        {heading}
      </Typography>
      <Divider color='#445569' sx={{ height: 2, marginBottom: 2 }} />
    </Box>
  )
}

export default HeadDivider

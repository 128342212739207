import Box from '@mui/material/Box'
import {
  ArticleOutlined,
  BeachAccessOutlined,
  FolderOutlined,
} from '@mui/icons-material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router-dom'
import { ButtonBase, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface QuickLinks {
  title: string
  link: string
  icon: ReactNode
}

const quickLinks: QuickLinks[] = [
  {
    title: 'My Documents',
    link: '/dashboard/documents',
    icon: <FolderOutlined />,
  },
  {
    title: 'My Research',
    link: '/dashboard/research',
    icon: <ArticleOutlined />,
  },
  {
    title: 'My Leave',
    link: '/dashboard/leave',
    icon: <BeachAccessOutlined />,
  },
]

export default function QuickLinksCard() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        padding: '24px',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: 'primary.main',
        height: '100%',
      }}>
      <Typography variant='h6'>Quick Links</Typography>
      <Grid2 container spacing={2} marginTop={'8px'}>
        {quickLinks.map((link, key) => {
          return (
            <Grid2 xs={6} sm={6} md={4} lg={6} key={key}>
              <ButtonBase
                component={'div'}
                onClick={() => {
                  navigate(link.link)
                }}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },
                  gap: '4px',
                  alignItems: 'center',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'background.default',
                  cursor: 'pointer',
                }}>
                {link.icon}
                <Box
                  component={'p'}
                  sx={{
                    fontSize: {
                      xs: '0.9rem',
                      sm: '1rem',
                    },
                  }}>
                  {link.title}
                </Box>
              </ButtonBase>
            </Grid2>
          )
        })}
      </Grid2>
    </Box>
  )
}


import { Button } from "@mui/material";

import useStore from "../store/store";

const DeleteLeave = () => {
  const { setShowDialog } = useStore();

  const handleClose = () => {
    setShowDialog(false);
  };

  // const { handleSubmit, setValue } = useForm();

  // const { status, mutateAsync } = useMutation({
  //   mutationKey: ["deleteCompOff"],
  //   mutationFn: () => {
  //     return deleteCompOff(compOffEntryId);
  //   },
  //   onError: () => {
  //     setShowLoader(false);
  //     setShowDialog(false);
  //     setToastSeverity("error");
  //     setToastMessage("Error deleting comp off");
  //     setShowToast(true);
  //   },
  //   onSuccess: () => {
  //     setShowLoader(false);
  //     setShowDialog(false);
  //     setToastSeverity("success");
  //     setToastMessage("Comp off deleted successfully");
  //     setShowToast(true);
  //   },
  // });

  // if (status === "pending") setShowLoader(true);

  // const onSubmit = handleSubmit(async () => {
  //   await mutateAsync();
  // });

  // useEffect(() => {
  //   setValue("entryID", compOffEntryId);
  // }, []);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        sx={{
          margin: "8px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          // delete comp off logic here
          // onSubmit();
        }}
        variant="contained"
        sx={{
          margin: "8px",
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "error.dark",
          },
        }}
        type="submit"
      >
        Delete
      </Button>
    </>
  );
};

export default DeleteLeave;

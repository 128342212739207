import { create } from "zustand";
import { createUserSlice } from "./user-slice";
import { createDialogSlice } from "./dialog-slice";
import { createAuthSlice } from "./auth-slice";
import { createMiscSlice } from "./misc-slice";
import { Store } from "../types/store";

// Combine all slices into a single store
const useStore = create<Store>()((...a) => ({
  ...createAuthSlice(...a),
  ...createDialogSlice(...a),
  ...createMiscSlice(...a),
  ...createUserSlice(...a),
}));

export default useStore;

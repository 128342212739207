import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import HeadDivider from "../components/ui/HeadDivider";
import {
  Box,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

function ICardPrinting() {
  const [printingForm, setPrintingForm] = useState({
    iCardFor: "",
    iCardForYear: "",
  });
  const handlePrintingFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrintingForm({
      ...printingForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <Grid2 container spacing={2} my={1}>
        <Grid2 xs={12} md={12}>
          <HeadDivider heading="ICard Printing" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="ICard For"
                  name="iCardFor"
                  select
                  value={printingForm.iCardFor}
                  onChange={handlePrintingFormChange}
                  fullWidth
                >
                  <MenuItem value="student">Student</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Select Year"
                  name="iCardForYear"
                  select
                  value={printingForm.iCardForYear}
                  onChange={handlePrintingFormChange}
                  fullWidth
                >
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                </TextField>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
      {/* HISTORY */}
      <HeadDivider heading="Register" />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Requisition No.</TableCell>
              <TableCell>Requisition Date</TableCell>
              <TableCell>Requisition By</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {leaveData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}.</TableCell>
                  <TableCell>{row.RequisitionNo}</TableCell>
                  <TableCell>{row.RequisitionDate}</TableCell>
                  <TableCell>{row.RequisitionBy}</TableCell>
                  <TableCell>{row.Quantity}</TableCell>
                  <TableCell>
                    <Button variant="text" sx={{ p: 0 }}>
                      View
                    </Button>
                    <IconButton color="error" sx={{ p: 0 }}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={20} />
              </TableRow>
            )}
          </TableBody> */}
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={leaveData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </>
  );
}

export default ICardPrinting;

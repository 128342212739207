import { forwardRef } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";

interface LabelledSelectInputProps {
  options: string[];
  value: string;
  label: string;
  name?: string;
  error?: boolean;
  errorMessage?: any;
  required?: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
}

// Use React.forwardRef to forward refs to the Select component
const LabelledSelectInput = forwardRef<
  HTMLDivElement,
  LabelledSelectInputProps
>(
  (
    { options, value, label, name, error, errorMessage, required, onChange },
    ref
  ) => {
    const labelSlug = label.toLowerCase().split(" ").join("-");

    return (
      <FormControl fullWidth size="small" error={error} ref={ref}>
        <InputLabel id={`${labelSlug}-label`}>{label}</InputLabel>
        <Select
          labelId={`${labelSlug}-label`}
          id={labelSlug}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  }
);

export default LabelledSelectInput;

import { useQuery } from "@tanstack/react-query";
import {
  bindLeaveTypes,
  deleteLeaveRecord,
  displayCompOff,
  displayLeaveData,
  displayMissPunch,
  getCompOffCreditBalance,
  getCompOffRegister,
  getHolidayList,
  getInchargeStaff,
  getLeaveBalanceData,
  getLeaveTypes,
  getMissPunchRegister,
  getProfileInfo,
} from "./api";

export function useGetProfileInfo() {
  return useQuery({
    queryKey: ["profile"],
    queryFn: getProfileInfo,
    refetchOnWindowFocus: false,
  });
}

export function useGetHolidayList(year: number) {
  return useQuery({
    queryKey: ["holiday-list", { year }],
    queryFn: () => getHolidayList(year),
    refetchOnWindowFocus: false,
  });
}

export function useCompOffRegister() {
  return useQuery({
    queryKey: ["compoff-register"],
    queryFn: getCompOffRegister,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useDisplayCompOff(entryId: string) {
  return useQuery({
    queryKey: ["compoff-display", { entryId }],
    queryFn: () => displayCompOff(entryId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });
}

export function useMissPunchRegister(fromDate: string, toDate: string) {
  return useQuery({
    queryKey: ["misspunch-register", { fromDate, toDate }],
    queryFn: () => getMissPunchRegister(fromDate, toDate),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useDisplayMissPunch(entryId: string) {
  return useQuery({
    queryKey: ["misspunch-display", { entryId }],
    queryFn: () => displayMissPunch(entryId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });
}

export function useLeaveBalanceData() {
  return useQuery({
    queryKey: ["leave-balance-data"],
    queryFn: getLeaveBalanceData,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useCompOffCreditBalance() {
  return useQuery({
    queryKey: ["compoff-credit-balance"],
    queryFn: getCompOffCreditBalance,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useLeaveTypes() {
  return useQuery({
    queryKey: ["leave-types"],
    queryFn: getLeaveTypes,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useInchargeStaff(type: "dept" | "all") {
  return useQuery({
    queryKey: ["incharge-staff", { type }],
    queryFn: () => getInchargeStaff(type),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useDisplayLeaveData(leaveEntryCode: string) {
  return useQuery({
    queryKey: ["leave-data", { leaveEntryCode }],
    queryFn: () => displayLeaveData(leaveEntryCode),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useBindLeaveTypes() {
  return useQuery({
    queryKey: ["leave-types"],
    queryFn: bindLeaveTypes,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useDeleteLeaveRecord(leaveEntryCode: string) {
  return useQuery({
    queryKey: ["delete-leave-record", { leaveEntryCode }],
    queryFn: () => deleteLeaveRecord(leaveEntryCode),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

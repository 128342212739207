import {
  Box,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import LabelledSelectInput from '../components/ui/LabelledSelectInput'
import { useState } from 'react'

const TelephoneExtensions = () => {
  // State variables for form
  const [institute, setInstitute] = useState('')

  const handleInstituteChange = (event: SelectChangeEvent) => {
    setInstitute(event.target.value)
  }

  const instituteOptions = [
    'DPU',
    'Medical College',
    'Hospital',
    'Nursing College',
    'Physiotherapy College',
    'Occupational Therapy College',
    'Management College',
    'Biotechnology and Bioinformatics College',
    'Optometry College',
    'Pharmacy College',
    'Law College',
    'Architecture College',
    'Design College',
    'Commerce and Economics College',
    'Science and Technology College',
  ]

  // Dummy data for display
  const telephoneList = [
    {
      id: 1,
      Institute: 'DYPVP',
      Particulers: 'Reception [Administration]',
      ExtensionNo: 5000,
    },
    {
      id: 2,
      Institute: 'DYPVP',
      Particulers: 'Reception [Administration]',
      ExtensionNo: 5001,
    },
    {
      id: 3,
      Institute: 'DYPVP',
      Particulers: 'President-Dr.P.D.Patil [Administration]',
      ExtensionNo: 5002,
    },
    {
      id: 4,
      Institute: 'DYPVP',
      Particulers: 'Pre-Dr.P.D.Patil [Administration]',
      ExtensionNo: 5004,
    },
    {
      id: 5,
      Institute: 'DYPVP',
      Particulers: 'Secretary-Mr.Somnath Patil [Administration]',
      ExtensionNo: 5006,
    },
    {
      id: 6,
      Institute: 'DYPVP',
      Particulers: 'Secre-Mr.Somnath Patil [Administration]',
      ExtensionNo: 5007,
    },
    {
      id: 7,
      Institute: 'DYPVP',
      Particulers: 'Exe.Director-Mrs.Smita Jadhav [Administration]',
      ExtensionNo: 5008,
    },
    {
      id: 8,
      Institute: 'DYPVP',
      Particulers: 'V.C-Dr.P.N.Razdan [Administration]',
      ExtensionNo: 5011,
    },
    {
      id: 9,
      Institute: 'DYPVP',
      Particulers: 'H.C-Prof.K.B.Powar [Administration]',
      ExtensionNo: 5013,
    },
    {
      id: 10,
      Institute: 'DYPVP',
      Particulers: 'Deputy Register-Mr.D.C.Ghogare [Administration]',
      ExtensionNo: 5014,
    },
  ]

  return (
    <div>
      <Typography
        variant='h1'
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}>
        Telephone Extensions
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 sm={12}>
          <Box
            sx={{
              backgroundColor: 'white',
              border: '1px solid',
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
              height: '100%',
            }}>
            <form>
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={6}>
                  <Typography sx={{ paddingBottom: 2 }}>
                    Select an institute from the below list to show telephone
                    extensions:
                  </Typography>
                    <LabelledSelectInput
                    name={institute}
                    value={institute}
                    label='Select Institute'
                    onChange={handleInstituteChange}
                    options={instituteOptions}
                  />
                </Grid2>
              </Grid2>
            </form>
          </Box>
        </Grid2>
      </Grid2>
      <Box sx={{ height: 24 }} />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Institute</TableCell>
              <TableCell>Particulars</TableCell>
              <TableCell>Extension</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {telephoneList.map((row) => (
              <TableRow key={row.id}>
                <TableCell component='th' scope='row'>
                  {row.id}.
                </TableCell>
                <TableCell>{row.Institute}</TableCell>
                <TableCell>{row.Particulers}</TableCell>
                <TableCell>{row.ExtensionNo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TelephoneExtensions

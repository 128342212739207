import { Box, SelectChangeEvent, Typography } from '@mui/material'
import SelectInput from '../ui/SelectInput'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import MyPieChart from '../ui/PieChart'
import Legends from '../ui/Legends'

interface OverviewChartProps {
  year: string | undefined
  yearOptions: string[] | undefined
  handleYearChange: (event: SelectChangeEvent) => void
  yearSelectionPresent: boolean
  title: string
  label1: string
  label2: string
  percentage: string | undefined
  value1: number | undefined
  value2: number | undefined
}

const OverviewChart = ({
  year,
  yearOptions,
  handleYearChange,
  yearSelectionPresent,
  title = 'Pie Chart',
  label1 = 'Value 1',
  label2 = 'Value 2',
  value1 = 0,
  value2 = 0,
  percentage = '-',
}: OverviewChartProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          backgroundColor: 'white',
          border: '1px solid',
          borderRadius: 3,
          padding: 3,
          borderColor: (theme) => theme.palette.primary.main,
          height: '100%',
          position: 'relative',
        }}>
        {/* TODO: Overall attendance pie chart */}
        <Typography variant='h6' sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: '32px',
            right: '24px',
          }}>
          {yearSelectionPresent && (
            <SelectInput
              value={year ?? ''}
              options={yearOptions ?? []}
              onChange={handleYearChange}
            />
          )}
        </Box>
        <Grid2
          container
          sx={{
            placeItems: 'center',
            placeContent: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}>
          <Grid2 xs={12} sm={6} md={12} lg={6}>
            <Typography variant='h2' fontWeight={500} color={'secondary.main'}>
              {percentage}
            </Typography>
          </Grid2>
          <Grid2 xs={12} sm={6} md={12} lg={6} alignSelf={'center'}>
            <MyPieChart
              label1={label1}
              label2={label2}
              value1={value1}
              value2={value2}
            />
          </Grid2>
        </Grid2>
        <Legends label1={label1} label2={label2} />
      </Box>
    </>
  )
}

export default OverviewChart

import Logout from "../actions/Logout";
import SendWishes from "../actions/SendWishes";
import ChangePassword from "../actions/ChangePassword";
import { ChangePasswordForm } from "../components/ChangePasswordForm";
import Textarea from "../components/ui/Textarea";
import DeleteCompOff from "../actions/DeleteCompOff";
import ViewDetails from "../components/ViewDetails";
import DeleteLeave from "../actions/DeleteLeave";

export interface DialogVariants {
  type:
    | "logout"
    | "sendWishes"
    | "changePassword"
    | "eventDetails"
    | "deleteCompOff"
    | "viewDetails"
    | "deleteLeave";
}

export const DialogAction = {
  logout: <Logout />,
  sendWishes: <SendWishes />,
  changePassword: <ChangePassword />,
  deleteCompOff: <DeleteCompOff />,
  eventDetails: null,
  viewDetails: null,
  deleteLeave: <DeleteLeave />,
};

export const DialogBody = {
  logout: "Are you sure you want to logout?",
  sendWishes: <Textarea placeholder="Write your message here..." />,
  changePassword: <ChangePasswordForm />,
  eventDetails: "Event Details goes here...",
  deleteCompOff: "Are you sure you want to delete this comp off?",
  viewDetails: <ViewDetails />,
  deleteLeave: "Are you sure you want to delete this leave record?",
};

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  RemoveRedEyeOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import HeadDivider from "../components/ui/HeadDivider";
import theme from "../config/AppTheme";
import { useEffect, useState } from "react";
import {
  categoriesOptions,
  classesOptions,
  coursesOptions,
  departmentsOptions,
  religionsOptions,
} from "../utils/utils";
import axios from "axios";
import { saveStudentEnrollment } from "../services/StudentEnrollment";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface FormValues {
  studentId: string;
  studentName: string;
  birthPlace: string;
  gender: string;
  maritalStatus: string;
  birthDate: Date | null;
  bloodGroup: string;
  cellNo: string;
  categoryId: number | string;
  religionId: number | string;
  caste: string;
  emailAddress: string;
  currentStatus: string;
  department: string;
  aadharNo: string;

  residenceContact: string;
  mothersName: string;
  parentEmail: string;
  parentOfficeContact: string;
  fatherCellNo: string;
  fathersName: string;
  prn: string;
  rollNo: string;
  academicYear: string;
  courseId: number | string;
  classId: number | string;
  admissionQuota: string;
  doa: Date | null;
  currentAddress: string;
  permanentAddress: string;
  countryId: number | string;
  stateId: string;
  city: string;
  pinCode: string;
  boardName: string;
  marksObtained: string;
  outOfMarks: string;
  percentage: string;
  courseIdType: string;
  passingYear: string;
  instituteName: string;
  instituteAddress: string;
}

interface CourseType {
  courseId: number;
  courseCode: string | null;
  courseName: string;
  shortName: string;
}

interface ClassType {
  classId: number;
  courseId: number;
  className: string;
}

interface ReligionType {
  religionId: number;
  religion: string;
}
interface DepartmentType {
  deptId: number;
  departmentName: string;
  departmentType: string;
  parentId: any;
  shortName: any;
  isPresent: any;
}

interface CategoryType {
  categoryId: number;
  category: string;
  isPresent: boolean;
  masterStaffs: any[];
}

interface CountryType {
  countryId: number;
  sortName: string;
  countryName: string;
  phoneCode: number;
  masterStates: any[];
}

const StudentEnrollment = () => {
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [courses, setCourses] = useState<CourseType[]>(coursesOptions);
  const [classes, setClasses] = useState<ClassType[]>(classesOptions);
  const [countries, setCountries] = useState<CountryType[]>();
  const [religions, setReligions] = useState<ReligionType[]>(religionsOptions);
  const [departments, setDepartments] =
    useState<DepartmentType[]>(departmentsOptions);
  const [categories, setCategories] =
    useState<CategoryType[]>(categoriesOptions);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      studentId: "",
      studentName: "",
      birthPlace: "",
      gender: "",
      maritalStatus: "",
      birthDate: null,
      bloodGroup: "",
      cellNo: "",
      categoryId: "",
      religionId: "",
      caste: "",
      emailAddress: "",
      currentStatus: "",
      department: "",
      aadharNo: "",
      residenceContact: "",
      mothersName: "",
      parentEmail: "",
      parentOfficeContact: "",
      fatherCellNo: "",
      fathersName: "",
      prn: "",
      rollNo: "",
      academicYear: "",
      courseId: "",
      classId: "",
      admissionQuota: "",
      doa: null,
      currentAddress: "",
      permanentAddress: "",
      countryId: "",
      stateId: "",
      city: "",
      pinCode: "",
      boardName: "",
      marksObtained: "",
      outOfMarks: "",
      percentage: "",
      courseIdType: "",
      passingYear: "",
      instituteName: "",
      instituteAddress: "",
    },
  });

  useEffect(() => {
    const getOptions = async () => {
      try {
        let categoriesData: CategoryType[] = (
          await axios.get("https://1600.sdctest.in/Master/mycategory")
        ).data;
        setCategories(categoriesData);

        let religionsData: ReligionType[] = (
          await axios.get("https://1600.sdctest.in/Master/Religions")
        ).data;
        setReligions(religionsData);

        let courseData: CourseType[] = (
          await axios.get("https://1600.sdctest.in/Master/Courses")
        ).data;
        setCourses(courseData);

        let classesData: ClassType[] = (
          await axios.get("https://1600.sdctest.in/Master/Class")
        ).data;
        setClasses(classesData);

        let departmentsData: DepartmentType[] = (
          await axios.get("https://1600.sdctest.in/Master/Departments")
        ).data;
        setDepartments(departmentsData);

        let countriesData: CountryType[] = (
          await axios.get("https://1600.sdctest.in/Master/Countries")
        ).data;
        setCountries(countriesData);
      } catch (err) {
        console.log(err);
      }
    };
    getOptions();
  }, []);

  const onSubmit = (data: any) => {
    console.log(data);
    saveStudentEnrollment(data);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImage(reader.result as string);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2} mt={1}>
        {/* Photo Upload Section */}
        <Grid2 xs={12} md={4}>
          <HeadDivider heading="Photo Upload" />
          <Box
            sx={{
              gap: "40px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <Stack direction={"row"}>
              <Stack>
                {/* Assume primaryData?.Photobyte is available */}
                <img
                  src={image?.toString()}
                  width={"120px"}
                  height={"120px"}
                  style={{
                    border: "2px solid #445569",
                    padding: "3px",
                  }}
                />
                <Grid2 marginTop={2} xs={6}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    color="success"
                    tabIndex={-1}
                    startIcon={<UploadFileOutlined />}
                  >
                    Upload
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid2>
              </Stack>
              <Grid2
                marginLeft={2}
                alignSelf={"center"}
                sx={{
                  padding: "8px",
                  borderRadius: "12px",
                  border: "1px solid",
                  borderColor: "#d69d00",
                  alignContent: "center",
                  color: "#d69d00",
                }}
              >
                <Typography variant="subtitle2">
                  1. Type: PNG/JPEG/JPG
                </Typography>
                <Typography variant="subtitle2">
                  2. Dimensions : 320 X 240 px
                </Typography>
                <Typography variant="subtitle2">
                  3. Size : &lt; 600 kb
                </Typography>
              </Grid2>
            </Stack>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={4}></Grid2>
        <Grid2 xs={12} md={4}>
          <HeadDivider heading="Search Student" />
          <Box
            sx={{
              gap: "40px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <Stack direction={"row"}>
              <Grid2 xs={12} md={6}>
                <TextField label="ID" value=""></TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Button variant="outlined" color="info">
                  Search
                </Button>
              </Grid2>
            </Stack>
            <Stack direction={"row"}>
              <Grid2 xs={12} md={6}>
                <TextField label="Name" value=""></TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Button variant="outlined" color="info">
                  Search
                </Button>
              </Grid2>
            </Stack>
          </Box>
        </Grid2>
        {/* Personal Info Section */}
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Personal Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={3}>
                <TextField
                  label="Student ID"
                  value={watch("studentId")}
                  {...register("studentId")}
                />
              </Grid2>
              <Grid2 xs={12} md={9}>
                <TextField
                  label="Student Name"
                  value={watch("studentName")}
                  {...register("studentName", {
                    required: true,
                  })}
                  error={!!errors.studentName}
                  // helperText={errors.staffName?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Birth Date"
                  {...register("birthDate")}
                  onChange={() => {}}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Place Of Birth"
                  value={watch("birthPlace")}
                  {...register("birthPlace")}
                />
              </Grid2>
              <Grid2 xs={5} md={4}>
                <TextField
                  label="Gender"
                  select
                  value={watch("gender")}
                  {...register("gender")}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={7} md={4}>
                <TextField
                  label="Marital Status"
                  select
                  value={watch("maritalStatus")}
                  {...register("maritalStatus")}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Blood Group"
                  select
                  value={watch("bloodGroup")}
                  {...register("bloodGroup")}
                >
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Aadhar No."
                  value={watch("aadharNo")}
                  {...register("aadharNo", {
                    required: true,
                  })}
                  error={!!errors.aadharNo}
                />
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Category"
                  select
                  value={watch("categoryId")}
                  {...register("categoryId")}
                >
                  {categories?.map((category: any) => (
                    <MenuItem
                      value={category.categoryId}
                      key={category.categoryId}
                    >
                      {category.category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>

              <Grid2 xs={6} md={4}>
                <TextField
                  label="Religion"
                  select
                  value={watch("religionId")}
                  {...register("religionId")}
                >
                  {religions?.map((religion: ReligionType) => (
                    <MenuItem
                      value={religion.religionId}
                      key={religion.religionId}
                    >
                      {religion.religion}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={4}>
                <TextField
                  label="Caste"
                  value={watch("caste")}
                  {...register("caste")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Personal Mobile"
                  value={watch("cellNo")}
                  {...register("cellNo")}
                  helperText="SMS will be sent on this."
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Personal Email"
                  value={watch("emailAddress")}
                  {...register("emailAddress", {
                    required: true,
                  })}
                  error={!!errors.emailAddress}
                  helperText="Password Reset will be sent on this."
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="College Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="PRN / GR No."
                  value={watch("prn")}
                  {...register("prn")}
                />
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Roll No"
                  value={watch("rollNo")}
                  {...register("rollNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <Controller
                  control={control}
                  name="academicYear"
                  render={({ field }) => (
                    <DatePicker
                      timezone="Asia/Kolkata"
                      {...field}
                      value={field.value || null}
                      format="YYYY"
                      label="Academic Year"
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} md={7}>
                <TextField
                  label="Course"
                  select
                  value={watch("courseId")}
                  {...register("courseId", {
                    required: true,
                  })}
                  error={!!errors.courseId}
                >
                  {courses?.map((course: CourseType) => (
                    <MenuItem value={course.courseId} key={course.courseId}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={5}>
                <TextField
                  label="Admission Quota"
                  select
                  value={watch("admissionQuota")}
                  {...register("admissionQuota")}
                >
                  <MenuItem value="General">General</MenuItem>
                  <MenuItem value="OBC">OBC</MenuItem>
                  <MenuItem value="SC/NT">SC/NT</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={7}>
                <TextField
                  label="Class"
                  select
                  value={watch("classId")}
                  {...register("classId")}
                >
                  {classes?.map((classItem: ClassType) => (
                    <MenuItem value={classItem.classId} key={classItem.classId}>
                      {classItem.className}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={5}>
                <TextField
                  label="Current Status"
                  value={watch("currentStatus")}
                  {...register("currentStatus")}
                />
              </Grid2>
              <Grid2 xs={12} md={7}>
                <TextField
                  label="Department"
                  select
                  value={watch("department")}
                  {...register("department", {
                    required: true,
                  })}
                  error={!!errors.department}
                >
                  {departments?.map((department: any) => (
                    <MenuItem value={department.deptId} key={department.deptId}>
                      {department.departmentName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={5}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Date Of Admission"
                  {...register("doa")}
                  onChange={() => {}}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Address" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12}>
                <TextField
                  multiline
                  minRows={2}
                  label="Current Address"
                  value={watch("currentAddress")}
                  {...register("currentAddress", {
                    required: true,
                  })}
                  error={!!errors.currentAddress}
                  // helperText={errors.currentAddress?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Country"
                  select
                  value={watch("countryId")}
                  {...register("countryId")}
                >
                  {countries ? (
                    countries.map((country: CountryType) => (
                      <MenuItem
                        value={country.countryId}
                        key={country.countryId}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>None</MenuItem>
                  )}
                </TextField>
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="State"
                  value={watch("stateId")}
                  {...register("stateId")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="City"
                  value={watch("city")}
                  {...register("city")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Pin Code"
                  value={watch("pinCode")}
                  {...register("pinCode")}
                />
              </Grid2>
              <Grid2 xs={12}>
                <TextField
                  multiline
                  minRows={2}
                  label="Permanent Address"
                  value={watch("permanentAddress")}
                  {...register("permanentAddress", {
                    required: true,
                  })}
                  error={!!errors.permanentAddress}
                  // helperText={errors.currentAddress?.message?.toString()}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Parent Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Father's Name"
                  value={watch("fathersName")}
                  {...register("fathersName")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Father's Mobile No."
                  value={watch("fatherCellNo")}
                  {...register("fatherCellNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Office Contact No."
                  value={watch("parentOfficeContact")}
                  {...register("parentOfficeContact")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Parent Email Address"
                  value={watch("parentEmail")}
                  {...register("parentEmail")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Mother's Name"
                  value={watch("mothersName")}
                  {...register("mothersName")}
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Residence Contact No"
                  value={watch("residenceContact")}
                  {...register("residenceContact")}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Add Educational Qualification" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Course Type"
                  select
                  value={watch("courseIdType")}
                  {...register("courseIdType")}
                >
                  <MenuItem value="MCA">MCA</MenuItem>
                  <MenuItem value="MBA">MCA</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Year Of Passing"
                  value={watch("passingYear")}
                  {...register("passingYear")}
                />
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Marks Gained"
                  value={watch("marksObtained")}
                  {...register("marksObtained")}
                />
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Out Of Marks"
                  value={watch("outOfMarks")}
                  {...register("outOfMarks")}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Percentage"
                  value={watch("percentage")}
                  {...register("percentage")}
                />
              </Grid2>
              <Grid2 xs={12} md={12}>
                <TextField
                  label="University / Board Name"
                  value={watch("boardName")}
                  {...register("boardName")}
                />
              </Grid2>
              <Grid2 xs={12} md={12}>
                <TextField
                  label="Institute Name"
                  value={watch("instituteName")}
                  {...register("instituteName")}
                />
              </Grid2>
              <Grid2 xs={12} md={12}>
                <TextField
                  label="Location / Institute Address"
                  multiline
                  minRows={2}
                  value={watch("instituteAddress")}
                  {...register("instituteAddress")}
                />
              </Grid2>

              <Button
                sx={{ marginLeft: 1, marginTop: 1 }}
                variant="outlined"
                color="info"
              >
                Add
              </Button>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Educational Info" />
          <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
            <Table
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
                minWidth: {
                  sm: 620,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Course Type</TableCell>
                  <TableCell>University</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1.</TableCell>
                  <TableCell>BCA</TableCell>
                  <TableCell>SPPU</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <EditOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2.</TableCell>
                  <TableCell>MCA</TableCell>
                  <TableCell>SPPU</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <EditOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Upload Document" />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                gap: "24px",
                alignItems: "center",
              },
            }}
          >
            <Grid2 xs={12} md={8}>
              <TextField label="Document Type" select value="">
                <MenuItem value="PAN">PAN</MenuItem>
                <MenuItem value="Aadhar">Aadhar</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid2>
            <Grid2 xs={12} md={4}>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                color="success"
                tabIndex={-1}
                startIcon={<UploadFileOutlined />}
              >
                Upload
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Documents Uploaded" />
          <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
            <Table
              sx={{
                width: {
                  xs: "100%",
                },
                maxWidth: {
                  sm: 620,
                },
                minWidth: {
                  sm: 620,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1.</TableCell>
                  <TableCell>Aadhar</TableCell>
                  <TableCell>aadhar.pdf</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2.</TableCell>
                  <TableCell>PAN</TableCell>
                  <TableCell>pan.pdf</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      </Grid2>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        gap={4}
        marginTop={5}
      >
        <Button variant="outlined" color="info">
          Cancel
        </Button>
        <Button variant="outlined" color="warning">
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default StudentEnrollment;

export const religionsOptions = [
  {
    religionId: 1,
    religion: "Religion",
  },
  {
    religionId: 2,
    religion: "Hindu",
  },
  {
    religionId: 3,
    religion: "Muslim",
  },
  {
    religionId: 4,
    religion: "Jain",
  },
  {
    religionId: 5,
    religion: "Christian",
  },
  {
    religionId: 6,
    religion: "Sikh",
  },
  {
    religionId: 7,
    religion: "Buddhist",
  },
  {
    religionId: 8,
    religion: "-",
  },
];

export const coursesOptions = [
  {
    courseId: 1,
    courseCode: "001",
    courseName: "Bachelor of Engineering",
    shortName: "BE",
  },
  {
    courseId: 3,
    courseCode: "003",
    courseName: "Master of Business Administration",
    shortName: "MBA",
  },
  {
    courseId: 11,
    courseCode: "002",
    courseName: "Master of Engineering",
    shortName: "ME",
  },
  {
    courseId: 12,
    courseCode: null,
    courseName: "Ph.d",
    shortName: "Ph.d",
  },
];

export const classesOptions = [
  {
    classId: 7,
    courseId: 1,
    className: "FE-A",
  },
  {
    classId: 8,
    courseId: 1,
    className: "FE-B",
  },
  {
    classId: 9,
    courseId: 1,
    className: "FE-C",
  },
  {
    classId: 10,
    courseId: 1,
    className: "FE-D",
  },
];

export const departmentsOptions = [
  {
    deptId: 1,
    departmentName: "Electronics",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 2,
    departmentName: "Information Technology",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 3,
    departmentName: "Vidya Pratishthan",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 4,
    departmentName: "Administration",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 5,
    departmentName: "Library",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 6,
    departmentName: "Computer",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 8,
    departmentName: "Pratishthan",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 9,
    departmentName: "Electrical",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 10,
    departmentName: "Applied Science",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 11,
    departmentName: "Civil",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 12,
    departmentName: "Electronics & Telecommunication",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 13,
    departmentName: "Instrumentation",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 14,
    departmentName: "Mathematics",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 15,
    departmentName: "Master of Business Administration",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 16,
    departmentName: "Mechanical",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 18,
    departmentName: "First Year",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 21,
    departmentName: "College ERP Cell",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 24,
    departmentName: "PHYSICS",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 25,
    departmentName: "CHEMISTRY",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 26,
    departmentName: "GENERAL",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 27,
    departmentName: "COMMUNICATION SKILL",
    departmentType: "Administration",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 52,
    departmentName: "Purchase Dept",
    departmentType: "Administration",
    parentId: null,
    shortName: "CPD",
    isPresent: null,
  },
  {
    deptId: 53,
    departmentName: "Construction",
    departmentType: "Administration",
    parentId: null,
    shortName: "Construction",
    isPresent: null,
  },
  {
    deptId: 54,
    departmentName: "Information Technology",
    departmentType: "Administration",
    parentId: null,
    shortName: "IT",
    isPresent: null,
  },
  {
    deptId: 55,
    departmentName: "Software Development Cell",
    departmentType: "Administration",
    parentId: null,
    shortName: "SDC",
    isPresent: null,
  },
  {
    deptId: 56,
    departmentName: "Contract Staff",
    departmentType: "Administration",
    parentId: 4,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 57,
    departmentName: "Security Staff",
    departmentType: "Administration",
    parentId: 4,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 58,
    departmentName: "Management",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 59,
    departmentName: "Second Year",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 60,
    departmentName: "Artificial Intelligence & Data Science ",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 61,
    departmentName: "Automation and Robotics",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
  {
    deptId: 62,
    departmentName: "PHD",
    departmentType: "Academics",
    parentId: null,
    shortName: null,
    isPresent: null,
  },
];

export const categoriesOptions = [
  {
    categoryId: 1,
    category: "OBC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 2,
    category: "SC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 3,
    category: "SBC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 4,
    category: "VJ",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 5,
    category: "NT-B",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 6,
    category: "Open",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 7,
    category: "ST",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 8,
    category: "NT-C",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 9,
    category: "NT-D",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 10,
    category: "TFWS",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 11,
    category: "GOI",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 12,
    category: "J & K / SS",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 13,
    category: "ESBC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 14,
    category: "NRI",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 15,
    category: "PIO",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 16,
    category: "JK ",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 17,
    category: "EBC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 18,
    category: "SEBC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 19,
    category: "EWS",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 20,
    category: "RIO ",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 21,
    category: "GWGC",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 22,
    category: "FRN",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 23,
    category: "PMSS ",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 24,
    category: "NEUT",
    isPresent: true,
    masterStaffs: [],
  },
  {
    categoryId: 25,
    category: "SC-F",
    isPresent: true,
    masterStaffs: [],
  },
];

export function getMonthName(month: number) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month - 1];
}

export function getWeekday(day: number, month: number, year: number) {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(`${year}-${month}-${day}`);
  const weekdayIndex = date.getDay();
  return weekdays[weekdayIndex];
}

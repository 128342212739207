import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import CompOff from "../pages/CompOff";
import AppContainer from "../components/AppContainer";
import Login from "../pages/Login";
import LeaveApplication from "../pages/LeaveApplication";
import MissPunch from "../pages/MissPunch";
import TelephoneExtensions from "../pages/TelephoneExtensions";
import GrievanceRedressal from "../pages/GrievanceRedressal";
import CasteDiscrimination from "../pages/CasteDiscrimination";
import HolidaysList from "../pages/HolidaysList";
import Profile from "../pages/Profile";
import MyAttendance from "../pages/MyAttendance";
import FullCalendar from "../pages/FullCalendar";
import SocialConnect from "../pages/SocialConnect";
import WomenHarassment from "../pages/WomenHarassment";
import PageNotFoundDashboard from "../components/dashboard/PageNotFound";
import PageNotFound from "../components/PageNotFound";
import StaffEnrollment from "../pages/StaffEnrollment";
import StudentEnrollment from "../pages/StudentEnrollment";
import ICardRequisition from "../pages/ICardRequisition";
import ICardPrinting from "../pages/ICardPrinting";
import ProtectedRoutes from "./ProtectedRoutes";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      {/* Protected Routes */}
      <Route path="/secured" element={<ProtectedRoutes />}>
        <Route path="/secured/" element={<AppContainer />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="my-links">
            <Route path="comp-off" element={<CompOff />} />
            <Route path="leave-application" element={<LeaveApplication />} />
            <Route path="miss-punch" element={<MissPunch />} />
            <Route
              path="telephone-extensions"
              element={<TelephoneExtensions />}
            />
            <Route
              path="grievance-redressal"
              element={<GrievanceRedressal />}
            />
            <Route
              path="caste-discrimination"
              element={<CasteDiscrimination />}
            />
            <Route path="holiday-list" element={<HolidaysList />} />
            <Route path="my-profile" element={<Profile />} />
            <Route path="my-attendance" element={<MyAttendance />} />
            <Route path="academic-calendar" element={<FullCalendar />} />
            <Route path="social-connect" element={<SocialConnect />} />
            <Route path="women-harassment" element={<WomenHarassment />} />
          </Route>
          <Route path="hr">
            <Route path="student-enrollment" element={<StudentEnrollment />} />
            <Route path="staff-enrollment" element={<StaffEnrollment />} />
          </Route>
          <Route path="admissions">
            <Route path="icard-requisition" element={<ICardRequisition />} />
            <Route path="icard-printing" element={<ICardPrinting />} />
          </Route>
          <Route path="*" element={<PageNotFoundDashboard />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;

import axios from "axios";

const convertTimeToMinutes = (time: string) => {
  const timeArray = time.split(":");
  const hours = parseInt(timeArray[0]);
  const minutes = parseInt(timeArray[1]);
  return hours * 60 + minutes;
};

const calculateLateInDifference = (shiftTime: string, loginTime: string) => {
  if (shiftTime === null || loginTime === null) {
    return null;
  }
  const shiftTimeArray = shiftTime.split(" ");
  const loginTimeArray = loginTime.split(" ");
  const shiftTimeInMinutes = convertTimeToMinutes(shiftTimeArray[0]);
  const loginTimeInMinutes = convertTimeToMinutes(loginTimeArray[0]);
  const difference = loginTimeInMinutes - shiftTimeInMinutes;
  const hours = Math.floor(difference / 60);
  const minutes = difference % 60;

  if (hours <= 0) {
    if (minutes < 0) {
      return null;
    }
    return `${minutes}mins`;
  }

  return `${hours}hr ${minutes}mins`;
};

const convertTimeToDecimal = (time: string) => {
  if (!time) {
    return 0;
  }
  const timeArray = time.split(":");
  const hours = parseInt(timeArray[0]);
  const minutes = parseInt(timeArray[1]);
  const decimal = hours + minutes / 60;
  return Number(decimal.toFixed(2));
};

interface GraphData {
  xaxis: number[];
  yaxis: number[];
}

interface AttendanceData {
  date: string;
  status: string;
  shiftTime: string;
  inTime: string;
  outTime: string;
  totalHours: string;
  lateInDifference: string | null;
}

interface MonthlyAttendancePieChartData {
  absentCount: number;
  presentCount: number;
  presentPercentage: string;
}

interface MonthlyInTimePieChartData {
  onTimeCount: number;
  lateCount: number;
  onTimePercentage: string;
}

const GetAttendance = async (staffId: string, month: string, year: string) => {
  const URL = `/api/MyLinksStaff/GetAttendance?StaffId=${staffId}&MonthYear=${month}-${year}`;
  const response = await axios.get(URL);
  const tableData: Array<AttendanceData> = [];
  const graphData: GraphData = {
    xaxis: [],
    yaxis: [],
  };

  let absentCount = 0;
  let presentCount = 0;

  let lateCount = 0;

  response.data.forEach((item: any) => {
    tableData.push({
      date: item.Date1 || "-",
      status: item.Remarks.trim() || "-",
      shiftTime: item.ShiftTime || "-",
      inTime: item.LoginTime || "-",
      outTime: item.LogOutTime || "-",
      totalHours: item.TotalHours || "-",
      lateInDifference: calculateLateInDifference(
        item.ShiftTime,
        item.LoginTime
      ),
    });
    graphData.xaxis.push(parseInt(item.Date1.substring(0, 2)));
    graphData.yaxis.push(convertTimeToDecimal(item.TotalHours));
    if (item.Remarks?.trim() === "Absent") {
      absentCount++;
    }
    if (item.Remarks?.includes("Present")) {
      presentCount++;
    }
    // Calculate late count based on check-in time
    if (item.Remarks?.includes("Present")) {
      const loginTime = item.LoginTime.split(" ")[0];
      const shiftTime = item.ShiftTime.split(" ")[0];
      if (convertTimeToMinutes(loginTime) > convertTimeToMinutes(shiftTime)) {
        lateCount++;
      }
    }
  });

  const MonthlyAttendancePieChartData: MonthlyAttendancePieChartData = {
    absentCount,
    presentCount,
    presentPercentage: `${Math.ceil(
      (presentCount / (presentCount + absentCount)) * 100
    )}%`,
  };

  const MonthlyInTimePieChartData: MonthlyInTimePieChartData = {
    onTimeCount: presentCount - lateCount,
    lateCount,
    onTimePercentage: `${Math.ceil(
      ((presentCount - lateCount) / presentCount) * 100
    )}%`,
  };

  return {
    tableData,
    graphData,
    MonthlyAttendancePieChartData,
    MonthlyInTimePieChartData,
  };
};

const GetAttendanceGraphData = async (
  staffId: string,
  month: string,
  year: string
) => {
  const URL = `/api/MyLinksStaff/GetAttendance?StaffId=${staffId}&MonthYear=${month}-${year}`;
  const response = await axios.get(URL);
  const graphData: GraphData = {
    xaxis: [],
    yaxis: [],
  };

  response.data.forEach((item: any) => {
    graphData.xaxis.push(parseInt(item.Date1.substring(0, 2)));
    graphData.yaxis.push(convertTimeToDecimal(item.TotalHours));
  });

  return graphData;
};

export { GetAttendance, GetAttendanceGraphData };

import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DrawerContent from "./navigation/DrawerContent";
import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, Badge, SwipeableDrawer, Tooltip } from "@mui/material";
import {
  //DescriptionOutlined,
  LogoutOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileToolbar from "./ui/MobileToolbar";
import PagesSearchbar from "./ui/PagesSearchbar";
import useStore from "../store/store";

const drawerWidth = 230;

export default function AppContainer() {
  const {
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
    name,
    profileImage,
    expandMobileSearchBar,
  } = useStore();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/secured/my-links/my-profile");
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isTabletScreen = useMediaQuery("(max-width:960px)");

  // const toggleTodoState = () => {
  //   setShowTodo(!showTodo);
  //   if (todoRef?.current) {
  //     todoRef.current.style.transform = "translate(0px, 0px)";
  //   }
  // };

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      {/* <TodoContainer /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            border: "1px solid",
            borderColor: "primary.main",
            overflowX: "hidden",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: expandMobileSearchBar ? "none" : { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: expandMobileSearchBar ? "none" : "block",
            }}
          >
            {isSmallScreen
              ? "SBMR"
              : "School of Business Management and Research"}
          </Typography>
          {isTabletScreen ? (
            <MobileToolbar />
          ) : (
            <Box sx={{ flexGrow: 0, display: "flex", gap: "8px" }}>
              {/* <SearchBar /> */}
              <PagesSearchbar />
              {/* <Tooltip title="To-do List">
                <IconButton
                  sx={{
                    color: showTodo ? "secondary.main" : "inherit",
                    backgroundColor: showTodo
                      ? "background.default"
                      : "inherit",
                  }}
                  onClick={toggleTodoState}
                >
                  <DescriptionOutlined />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Notifications">
                <IconButton color="inherit">
                  <Badge badgeContent={4} color="warning">
                    <NotificationsOutlined />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={name}>
                <IconButton sx={{ p: 0 }} onClick={handleNavigate}>
                  <Avatar
                    alt={name}
                    src={`data:image/png;base64,${profileImage}`}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Logout">
                <IconButton
                  color="error"
                  onClick={() => {
                    setShowDialog(true);
                    setDialogTitle("Logout");
                    setDialogVariant("logout");
                  }}
                >
                  <LogoutOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          color: "white",
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          onOpen={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              margin: "10px",
              height: "calc(100% - 20px)",
              borderRadius: "12px",
            },
            height: "calc(100% - 20px)",
            margin: "10px",
            borderRadius: "12px",
          }}
        >
          <DrawerContent handleDrawerToggle={handleDrawerToggle} />
        </SwipeableDrawer>
        <Drawer
          variant="permanent"
          classes={{ paper: "drawer" }}
          sx={{
            backgroundColor: "primary.main",
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              margin: "10px",
              height: "calc(100% - 20px)",
              borderRadius: "12px",
            },
          }}
          open
        >
          <DrawerContent />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: "22px",
          pr: "12px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: "64px",
          maxWidth: "100vw",
        }}
        // sx={{ flexGrow: 1, maxWidth: '100vw', width: { sm: `calc(100% - ${drawerWidth}px)`} }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

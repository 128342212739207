import { SearchOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import useStore from "../../store/store";
import PagesSearchbar from "./PagesSearchbar";

const MobilePageSearchBar = () => {
  const { expandMobileSearchBar, setExpandMobileSearchBar } = useStore();
  return (
    <>
      {expandMobileSearchBar ? (
        <PagesSearchbar />
      ) : (
        <IconButton
          onClick={() => {
            setExpandMobileSearchBar(true);
          }}
        >
          <SearchOutlined />
        </IconButton>
      )}
    </>
  );
};

export default MobilePageSearchBar;

import { Badge, ButtonBase, Typography } from "@mui/material";
import { ReactNode } from "react";
// import { useNavigate } from "react-router-dom";
import MessagesContainer from "./MessagesContainer";
//import useStore from "../../store/store";
interface DashboardQuickLinkProps {
  icon: ReactNode;
  title: string;
  href: string;
}

const DashboardQuickLink = ({ title, icon  }: DashboardQuickLinkProps) => {
//  const { showLatestMessages, setShowLatestMessages } = useStore();
  // const navigate = useNavigate();
  // const handleClick = (href: string) => {
  //   title === "Messages"
  //     ? setShowLatestMessages(!showLatestMessages)
  //     : navigate(href);
  //};
  return (
    <ButtonBase
      component="div"
      focusRipple
      onClick={() =>{}} //handleClick(href)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "start", md: "center" },
        padding: 2,
        borderRadius: 2,
        backgroundColor:
          title === "Messages" ? "primary.light" : "background.paper",
        color: "primary.main",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        gap: { xs: "6px", md: "0px" },
        textOverflow: "ellipsis",
        position: "relative",
      }}
    >
      {title === "Messages" ? (
        <Badge color="warning" badgeContent={3}>
          <>{icon}</>
        </Badge>
      ) : (
        icon
      )}

      <Typography
        sx={{
          fontSize: {
            xs: "0.9rem",
            sm: "1rem",
          },
          color: title === "Messages" ? "black" : "primary.main",
          fontWeight: title === "Messages" ? 500 : 400,
        }}
      >
        {title}
      </Typography>

      {title === "Messages" && <MessagesContainer />}
    </ButtonBase>
  );
};

export default DashboardQuickLink;

import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { pages } from "../../config/Pages";
import useStore from "../../store/store";

const TextInput = ({ params }: { params: TextFieldProps }) => {
  const { expandMobileSearchBar, setExpandMobileSearchBar } = useStore();
  return (
    <>
      <TextField
        autoFocus={expandMobileSearchBar}
        placeholder="Quick Search"
        {...params}
        variant="outlined"
        autoComplete="off"
        aria-placeholder='Quick Search"'
        sx={{
          borderRadius: "20px",
        }}
        InputProps={{
          ...params.InputProps,
          type: "search",
          sx: {
            color: "primary.main",
            paddingLeft: 0,
            borderRadius: "10px",
            "&:hover": {
              borderColor: "primary.main",
            },
            "&:focus": {
              borderColor: "primary.main",
            },
            // apply styles to the fieldset
            "& fieldset": {
              border: expandMobileSearchBar ? "none" : "1px solid",
              borderRadius: expandMobileSearchBar ? "0" : "10px",
            },
          },
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                margin: 0,
                padding: 0,
              }}
            >
              <IconButton
                onClick={() => {
                  expandMobileSearchBar && setExpandMobileSearchBar(false);
                }}
              >
                {expandMobileSearchBar ? <CloseOutlined /> : <SearchOutlined />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

const PagesSearchbar = () => {
  const navigate = useNavigate();
  const { expandMobileSearchBar, setExpandMobileSearchBar } = useStore();
  return (
    <>
      <Autocomplete
        freeSolo
        disableClearable
        options={pages.map((page) => page.name)}
        renderInput={(params) => <TextInput params={params} />}
        onChange={(_, value: string) => {
          let link = pages.find((p) => p.name === value);
          setExpandMobileSearchBar(false);
          navigate(link?.link ?? "/dashboard");
        }}
        sx={{
          width: expandMobileSearchBar ? "inherit" : "240px",
        }}
      />
    </>
  );
};

export default PagesSearchbar;

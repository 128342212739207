import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { useState, ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import useStore from "../../store/store";

interface NestedNavLinkProps {
  title: string;
  sublinks: Array<{
    text: string;
    route: string;
    openFormDialog?: boolean;
  }>;
  icon: ReactElement;
  handleDrawerToggle?: () => void;
}

const NestedNavLink = ({
  title,
  icon,
  sublinks,
  handleDrawerToggle,
}: NestedNavLinkProps) => {
  const [open, setOpen] = useState(false);
  const { setShowDialog, setDialogVariant, setDialogTitle } = useStore();

  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          marginX: "8px",
        }}
      >
        <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            padding: "16px 0",
            backgroundColor: "primary.dark",
          }}
          disablePadding
        >
          {sublinks.map((sublink) => {
            const isActive = pathname === `/secured/${sublink.route}`;
            return (
              <ListItemButton
                key={sublink.text}
                component={Link}
                to={sublink.route}
                sx={{
                  transition: "all 0.3s ease-in-out",
                  // pl: 4,
                  marginX: "6px",
                  borderRadius: "10px",
                  ...(isActive && {
                    backgroundColor: "background.paper",
                    color: "primary.main",
                    "&:hover": { background: "white" },
                  }),
                }}
                onClick={() => {
                  if (sublink.openFormDialog) {
                    setShowDialog(true);
                    setDialogVariant("changePassword");
                    setDialogTitle("Change password");
                  }
                  handleDrawerToggle && handleDrawerToggle();
                }}
              >
                <ListItemText primary={sublink.text} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default NestedNavLink;

import { Box, Typography } from '@mui/material'

interface LegendsProps {
  label1?: string
  label2?: string
}

const Legends = ({ label1 = 'Value 1', label2 = 'Value2' }: LegendsProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '100%',
              backgroundColor: '#00BC78',
            }}
          />
          <Typography variant='subtitle2'>{label1}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '100%',
              backgroundColor: '#DB880B',
            }}
          />
          <Typography variant='subtitle2'>{label2}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default Legends

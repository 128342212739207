import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Router from "./config/Routes";
import { CssBaseline } from "@mui/material";
import Dialog from "./components/Dialog";
import theme from "./config/AppTheme";
import Loader from "./components/Loader";
import NetworkIndicator from "./components/NetworkIndicator";
import { validateToken } from "./services/api";
import useStore from "./store/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const App = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setLoading } = useStore.getState();

  useEffect(() => {
    const checkTokenValidity = async () => {
      setLoading(true);

      try {
        const isValid = await validateToken();
        setLoading(false);

        if (isValid) {
          navigate("/secured/dashboard", { replace: true });
        } else {
          toast.info("Login to Continue");
          navigate("/", { replace: true });
        }
      } catch (error) {
        setLoading(false);
        console.error("Token validation failed:", error);
        toast.info("Login to Continue");
        navigate("/", { replace: true });
      }
    };

    if (!isAuthenticated) {
      checkTokenValidity();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NetworkIndicator />
        <Dialog />
        <Router />
        <Loader />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

import { StateCreator } from "zustand";
import { DialogVariants } from "../config/Dialog";
import { SingleCompOffDataType } from "../types/compOff";
import { MissPunchDataType } from "../types/missPunch";

// Define the DialogState type
type DialogState = {
  showDialog: boolean;
  showToast: boolean;
  dialogTitle: string;
  dialogVariant: DialogVariants["type"];
  viewDetails: MissPunchDataType[] | SingleCompOffDataType[] | undefined;
};

// Define the DialogActions type
type DialogActions = {
  setShowDialog: (value: boolean) => void;
  setShowToast: (value: boolean) => void;
  setDialogVariant: (value: DialogVariants["type"]) => void;
  setDialogTitle: (value: string) => void;
  setViewDetails: (
    value: MissPunchDataType[] | SingleCompOffDataType[] | undefined
  ) => void;
};

export type DialogSlice = DialogState & DialogActions;

// Create the slice using the StateCreator
export const createDialogSlice: StateCreator<DialogSlice> = (set) => ({
  showDialog: false,
  showToast: false,
  dialogTitle: "",
  dialogVariant: "sendWishes",
  viewDetails: undefined,

  // Implement the setShowDialog action
  setShowDialog: (value: boolean) => set({ showDialog: value }),

  // Implement the setShowToast action
  setShowToast: (value: boolean) => set({ showToast: value }),

  // Implement the setDialogVariant action
  setDialogVariant: (value: DialogVariants["type"]) =>
    set({ dialogVariant: value }),

  // Implement the setDialogTitle action
  setDialogTitle: (value: string) => set({ dialogTitle: value }),
  setViewDetails: (value) => set({ viewDetails: value }),
});

import { Navigate, Outlet } from "react-router-dom";
import useStore from "../store/store";

const ProtectedRoutes = () => {
  const { isAuthenticated } = useStore((state) => ({
    isAuthenticated: state.isAuthenticated,
  }));

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoutes;

import { StateCreator } from "zustand";

// Define the UserState type with individual user properties
type UserState = {
  name: string;
  profileImage: string;
  deptId: number;
  roleId: number;
};

// Define the UserActions type
type UserActions = {
  setName: (name: string) => void;
  setProfileImage: (profileImage: string) => void;
  setDeptId: (id: number) => void;
  setRoleId: (id: number) => void;
};

// Combine UserState and UserActions into a single UserSlice type
export type UserSlice = UserState & UserActions;

// Create the user slice using the StateCreator
export const createUserSlice: StateCreator<UserSlice> = (set) => ({
  // Initialize each property with default values
  name: "",
  profileImage: "",
  deptId: 0,
  roleId: 0,

  // Define actions to update each property individually
  setName: (name: string) => set(() => ({ name: name })),
  setProfileImage: (profileImage: string) =>
    set(() => ({ profileImage: profileImage })),
  setDeptId: (id: number) => set(() => ({ deptId: id })),
  setRoleId: (id: number) => set(() => ({ roleId: id })),
});

import Box from "@mui/material/Box";
import erp from "../../assets/erp-logo.png";
import List from "@mui/material/List";
import SingleNavLink from "./SingleNavLink.jsx";
import NestedNavLink from "./NestedNavLink.jsx";
import FacultyNav from "../../config/FacultyNav.js";
import { Link } from "react-router-dom";

interface DrawerContentProps {
  handleDrawerToggle?: () => void;
}

const DrawerContent = ({ handleDrawerToggle }: DrawerContentProps) => {
  return (
    <div>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          padding: "18px",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.paper",
            padding: "18px",
            borderRadius: "10px",
            WebkitTapHighlightColor: "transparent",
          }}
          component={Link}
          to={"/"}
        >
          <img src={erp} alt="erp" style={{ width: "170px", height: "55px" }} />
        </Box>
      </Box>
      <List>
        {FacultyNav.map((navItem, index) => {
          if (navItem.nestedLinks) {
            return (
              <NestedNavLink
                key={index}
                title={navItem.text}
                icon={navItem.icon}
                sublinks={navItem.nestedLinks}
                handleDrawerToggle={handleDrawerToggle}
              />
            );
          } else {
            return (
              <SingleNavLink
                key={index}
                text={navItem.text}
                icon={navItem.icon}
                route={navItem.route}
                handleDrawerToggle={handleDrawerToggle}
              />
            );
          }
        })}
      </List>
    </div>
  );
};

export default DrawerContent;

import { FC } from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'

interface SelectInputProps {
  options: string[]
  value: string
  onChange: (event: SelectChangeEvent) => void
}

const SelectInput: FC<SelectInputProps> = ({ options, value, onChange }) => {
  return (
    <FormControl>
      <Select
        value={value}
        onChange={onChange}
        variant='outlined'
        size='small'
        sx={{
          padding: '0',
          outline: 'none',
          fontSize: '14px',
        }}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectInput

import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import HeadDivider from "../components/ui/HeadDivider";
import LabelledSelectInput from "../components/ui/LabelledSelectInput";
import useStore from "../store/store";
import {
  useLeaveBalanceData,
  useCompOffCreditBalance,
  useLeaveTypes,
  useInchargeStaff,
  useDisplayLeaveData,
} from "../services/queries";
import {
  useSaveLeaveMutation,
  useUpdateLeaveMutation,
} from "../services/mutations";
import {
  LeaveFormData,
  SingleLeaveDataType,
} from "../types/leave";

const LeaveApplication: React.FC = () => {
  const [editingForm, setEditingForm] = useState(false);
  const [leaveEntryId, setLeaveEntryId] = useState("");
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const [fetchKey, setFetchKey] = useState<number>(0);
  const [entryDate, setEntryDate] = useState<dayjs.Dayjs>(dayjs());
  const [referenceNo, setReferenceNo] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [inchargeStaffType, setInchargeStaffType] = useState<"dept" | "all">(
    "dept"
  );

  const {
    setLoading,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
  } = useStore();

  const {
    control,
    register,
    getValues,
    setValue,
    reset,
  } = useForm<LeaveFormData>({
    defaultValues: {
      entryID: leaveEntryId,
      leaveType: "",
      dayType: "",
      personIncharge: "",
      fromDate: null,
      toDate: null,
      reason: "",
      address: "",
    },
  });

  const { data: leaveBalanceData, isLoading: leaveBalanceLoading } =
    useLeaveBalanceData();
  const {
    data: compOffCreditBalanceData,
    isLoading: compOffCreditBalanceLoading,
  } = useCompOffCreditBalance();
  const { data: leaveOptions, isLoading: leaveTypesLoading } = useLeaveTypes();
  const {
    data: inchargeStaff,
    isLoading: inchargeStaffLoading,
    refetch: refetchInchargeStaff,
  } = useInchargeStaff(inchargeStaffType);
  const {
    data: singleLeaveData,
    isLoading: displayLoading,
    refetch: refetchSingleLeave,
  } = useDisplayLeaveData(leaveEntryId);

  const { isPending: saveLoading } =
    useSaveLeaveMutation();
  const { isPending: updateLoading } =
    useUpdateLeaveMutation();
/*
  const onSaveHandler = async (data: LeaveSubmitFormData) => {
    await saveAsync(data);
    reset();
  };
*/
/*  const onUpdateHandler = async (data: LeaveSubmitFormData) => {
    data.entryID = leaveEntryId;
    await updateAsync(data);
    reset();
    setEditingForm(false);
    setReferenceNo("");
    setEntryDate(dayjs());
  };*/

  useEffect(() => {
    setLoading(
      saveLoading ||
        updateLoading ||
        leaveBalanceLoading ||
        compOffCreditBalanceLoading ||
        leaveTypesLoading ||
        inchargeStaffLoading ||
        displayLoading
    );
  }, [
    saveLoading,
    updateLoading,
    leaveBalanceLoading,
    compOffCreditBalanceLoading,
    leaveTypesLoading,
    inchargeStaffLoading,
    displayLoading,
    setLoading,
  ]);

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = useMemo(() => {
    if (!leaveBalanceData) return 0;
    return Math.max(
      0,
      rowsPerPage -
        Math.min(rowsPerPage, leaveBalanceData.length - page * rowsPerPage)
    );
  }, [leaveBalanceData, rowsPerPage, page]);

  const handleEditLeave = (id: string) => {
    setLeaveEntryId(id);
    setFetchKey((prev) => prev + 1);
    setEditingForm(true);
    setTextAreaFocused(true);
    window.scrollTo(0, 0);
  };

  const handleDisplayLeave = (id: string) => {
    setEditingForm(false);
    setLeaveEntryId(id);
    setFetchKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (singleLeaveData && Object.keys(singleLeaveData).length > 0) {
      if (editingForm) {
        const data: SingleLeaveDataType = singleLeaveData[0];
        const {
          ReferenceNo,
          ApplicationDate,
          LeaveType,
          DayType,
          PersonIncharge,
          FromDate,
          ToDate,
          Reason,
          Address,
        } = data;
        setReferenceNo(ReferenceNo);
        setEntryDate(dayjs(ApplicationDate));
        setValue("leaveType", LeaveType);
        setValue("dayType", DayType);
        setValue("personIncharge", PersonIncharge);
        setValue("fromDate", dayjs(FromDate));
        setValue("toDate", dayjs(ToDate));
        setValue("reason", Reason);
        setValue("address", Address);
        window.scrollTo(0, 0);
      } else {
        // setViewDetails(singleLeaveData);
        // setDialogTitle("Leave Application Details");
        // setDialogVariant("viewDetails");
        // setShowDialog(true);
      }
    }
  }, [
    singleLeaveData,
    editingForm,
    setValue,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
  ]);

  useEffect(() => {
    if (fetchKey > 0) {
      refetchSingleLeave();
    }
  }, [fetchKey, refetchSingleLeave]);

  useEffect(() => {
    refetchInchargeStaff();
  }, [inchargeStaffType, refetchInchargeStaff]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}
      >
        Leave Application
      </Typography>

      <HeadDivider heading="Balance" />
      <TableContainer sx={{ marginBottom: 2 }} component={Paper}>
        <Table sx={{ minWidth: 840 }}>
          <TableHead>
            <TableRow>
              <TableCell>Leave Type</TableCell>
              <TableCell>Allocation From</TableCell>
              <TableCell>Allocation Till</TableCell>
              <TableCell>Current Allocation</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Consumed</TableCell>
              <TableCell>Current Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveBalanceData &&
              leaveBalanceData.map((row: any, id: any) => (
                <TableRow key={id}>
                  <TableCell>{row.LeaveType}</TableCell>
                  <TableCell>{row.DateFrom}</TableCell>
                  <TableCell>{row.DateTo}</TableCell>
                  <TableCell>{row.CurrentAllocation}</TableCell>
                  <TableCell>{row.Total}</TableCell>
                  <TableCell>{row.Consumed}</TableCell>
                  <TableCell>{row.CurrentLeaveBalance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 20,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        Comp Off Credit:{" "}
        {compOffCreditBalanceData && compOffCreditBalanceData[0].CurrentBalance}
      </Typography>

      <Box sx={{ height: 18 }}></Box>

      <HeadDivider heading="Form" />
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={12}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: "20px 16px",
              borderColor: (theme) => theme.palette.primary.main,
              height: "100%",
            }}
          >
            {editingForm && (
              <Alert
                variant="standard"
                severity="info"
                sx={{ marginBottom: 2 }}
              >
                <Typography variant="body2">
                  {`Editing EntryId: ${leaveEntryId}`}
                </Typography>
              </Alert>
            )}

            <form
            // onSubmit={handleSubmit(
            //   editingForm ? onUpdateHandler : onSaveHandler
            // )}
            >
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={6}>
                  <TextField
                    label="Reference No."
                    value={referenceNo}
                    disabled
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <DatePicker
                    value={entryDate}
                    readOnly
                    label="Application Date"
                    format="DD/MM/YYYY"
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="leaveType"
                    render={({ field }) => (
                      <LabelledSelectInput
                        {...field}
                        label="Leave Type"
                        options={
                          leaveOptions?.map(
                            (option: { LeaveName: any }) => option.LeaveName
                          ) || []
                        }
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="dayType"
                    render={({ field }) => (
                      <LabelledSelectInput
                        {...field}
                        label="Day Type"
                        options={["Full Day", "Half Day", "Multiple Days"]}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="personIncharge"
                    render={({ field }) => (
                      <LabelledSelectInput
                        {...field}
                        label="Person Incharge"
                        options={
                          inchargeStaff?.map(
                            (option: { StaffName: any }) => option.StaffName
                          ) || []
                        }
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setInchargeStaffType(
                            e.target.checked ? "all" : "dept"
                          );
                        }}
                      />
                    }
                    label="Show All Staff"
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="fromDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="From Date"
                        format="DD/MM/YYYY"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="To Date"
                        format="DD/MM/YYYY"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField
                    {...register("reason")}
                    focused={textAreaFocused}
                    required
                    multiline
                    minRows={3}
                    label="Reason"
                    onBlur={() => {
                      getValues("reason") === "" && setTextAreaFocused(false);
                    }}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField
                    {...register("address")}
                    focused={textAreaFocused}
                    required
                    multiline
                    minRows={3}
                    label="Address & Contact No. during this duration"
                    onBlur={() => {
                      getValues("address") === "" && setTextAreaFocused(false);
                    }}
                  />
                </Grid2>
              </Grid2>

              {editingForm ? (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      reset();
                      setEditingForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      reset();
                      setEntryDate(dayjs());
                      setEditingForm(false);
                      setReferenceNo("");
                    }}
                  >
                    New
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Save
                  </Button>
                </>
              )}
            </form>
          </Box>
        </Grid2>
      </Grid2>

      {/* Approval Flow */}
      <Typography
        sx={{
          paddingY: 1,
          color: (theme) => theme.palette.primary.main,
          fontWeight: "bold",
        }}
      >
        Approval Flow:
      </Typography>
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Role/Staff name</TableCell>
              <TableCell>Status Date</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Leave Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1.</TableCell>
              <TableCell>HOD - Mr. Arun D. Sonar</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.</TableCell>
              <TableCell>HOD - Dr. L. K. Wadhwa</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* HISTORY */}
      <Box sx={{ height: 24 }} />
      <HeadDivider heading="History" />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Application Date</TableCell>
              <TableCell>Leave Date</TableCell>
              <TableCell>Days</TableCell>
              <TableCell>Leave Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveBalanceData &&
              leaveBalanceData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1 + page * rowsPerPage}.</TableCell>
                    <TableCell>{row.ApplicationDate}</TableCell>
                    <TableCell>{`${row.FromDate} to ${row.ToDate}`}</TableCell>
                    <TableCell>{row.Days}</TableCell>
                    <TableCell>{row.LeaveType}</TableCell>
                    <TableCell>{row.Reason}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "center",
                        margin: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleDisplayLeave(row.EntryID)}
                      >
                        <RemoveRedEyeOutlined />
                      </IconButton>
                      <IconButton
                        disabled={row.IsEditable === "False"}
                        color="primary"
                        onClick={() => handleEditLeave(row.EntryID)}
                      >
                        <EditOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDialogTitle(
                            `Delete Leave Application (ID: ${row.EntryID})`
                          );
                          setDialogVariant("deleteLeave");
                          setLeaveEntryId(row.EntryID);
                          setShowDialog(true);
                        }}
                        color="error"
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={leaveBalanceData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default LeaveApplication;

import { Box, Button, ButtonBase, Chip, Typography } from "@mui/material";
import cake from "../../assets/cake-icon.svg";
import birthday from "../../assets/birthday.jpg";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import Carousel from "react-material-ui-carousel";
import { ArrowOutwardOutlined } from "@mui/icons-material";

const SocialsCard = () => {
  const navigate = useNavigate();
  const { setDialogTitle, setShowDialog, setDialogVariant } = useStore();
  const upcomingBirthdays = [
    { name: "Lorem Ipsum" },
    { name: "Jane Doe" },
    { name: "Lorem Doe" },
    { name: "Ipsum Doe" },
  ];

  const handleClick = () => {
    setDialogTitle("Birthday Wish");
    setDialogVariant("sendWishes");
    setShowDialog(true);
  };
  return (
    <Box
      component={"div"}
      sx={{
        // backgroundImage
        background: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${birthday})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: '130px',
        borderRadius: "10px",
        color: "white",
        paddingBottom: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* header */}

      <Box
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "8px",
          padding: "16px 16px 0 16px",
        }}
      >
        <Box
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            fontWeight: 500,
          }}
        >
          <img src={cake} alt="cake" />
          <Typography variant="subtitle1" fontWeight={500}>
            Social Connect
          </Typography>
        </Box>

        <ButtonBase
          sx={{ padding: "4px", borderRadius: "50%" }}
          onClick={() => {
            navigate("/dashboard/social-connect");
          }}
        >
          <ArrowOutwardOutlined />
        </ButtonBase>
        <Box sx={{ flexGrow: 1 }} />
        <Chip
          label="Today"
          variant="filled"
          size="small"
          sx={{ marginLeft: "16px" }}
        />
      </Box>

      {/* header */}
      {/* upcoming 2 birthdays */}
      <Carousel
        indicators={false}
        autoPlay={false}
        animation={"slide"}
        navButtonsAlwaysVisible={true}
        navButtonsProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
        sx={{
          marginTop: "12px",
          height: "100px",
          padding: "0",
          backgroundColor: "transparent",
        }}
      >
        {upcomingBirthdays.map((birthday, key) => (
          <Box
            key={key}
            sx={{
              backgroundColor: "rgba(0,0,0,0.5)",
              padding: "8px",
              borderRadius: "10px",
              height: "100px",
              maxWidth: "90%",
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                src="https://picsum.photos/200/200"
                alt="profile"
                style={{ borderRadius: "50%", width: "75px", height: "75px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    color: "secondary.main",
                    fontStyle: "italic",
                  }}
                >
                  Birthday
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    // text ellipse
                    fontSize: "24px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  {birthday.name}
                </Typography>

                <Button
                  sx={{
                    marginTop: "8px",
                    backgroundColor: "#04B9AE",
                    "&:hover": {
                      backgroundColor: "#04B9AE",
                    },
                    textTransform: "none",
                    alignSelf: "flex-start",
                    margin: "2px 0 0 0",
                    padding: "0 10px",
                    color: "white",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                  onClick={handleClick}
                >
                  Wish her
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default SocialsCard;

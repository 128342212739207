import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20vh",
        // height: 'calc(100vh - 64px)',
        gap: "8px",
      }}
    >
      <Typography variant="h4" display={"flex"} alignItems={"center"}>
        <Box component={"span"} fontSize={48}>
          {" "}
          404
        </Box>
        <Box component={"span"} color={"primary.light"} padding={"0 6px"}>
          {" "}
          |{" "}
        </Box>
        Page Not Found
      </Typography>

      <Typography variant="body1" textAlign={"center"}>
        Sorry, the page you are looking for does not exist.
      </Typography>

      <Button
        variant="contained"
        size="medium"
        sx={{
          marginTop: "12px",
        }}
        onClick={() => {
          navigate("/secured/dashboard");
        }}
      >
        Go Back
      </Button>
    </Box>
  );
};

export default PageNotFound;

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PrimaryInfo from "../components/profile/PrimaryInfo";
import QuickLinksCard from "../components/profile/QuickLinksCard";
import { Typography } from "@mui/material";
import DetailsCard from "../components/profile/DetailsCard";
import { useGetProfileInfo } from "../services/queries";
import { useEffect, useState } from "react";
import {
  OfficialDataType,
  PersonalDataType,
  PrimaryDataType,
} from "../types/profile";

const Profile = () => {
  const [personalInfo, setPersonalInfo] = useState<PersonalDataType>();
  const [primaryInfo, setPrimaryInfo] = useState<PrimaryDataType>();
  const [officialInfo, setOfficialInfo] = useState<OfficialDataType>();

  const { data, isLoading, isError } = useGetProfileInfo();

  useEffect(() => {
    if (data) {
      const { personal, official, primary } = data;
      setPersonalInfo(personal);
      setPrimaryInfo(primary);
      setOfficialInfo(official);
    }
  }, [isError, isLoading]);

  return (
    <>
      <Typography
        variant="h1"
        sx={{
          fontSize: 30,
          fontWeight: 400,
          paddingY: 2,
        }}
      >
        My Profile
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} lg={7}>
          <PrimaryInfo
            name={primaryInfo?.StaffName}
            designation={primaryInfo?.Designation}
            email={primaryInfo?.EMailID}
            phone={primaryInfo?.CellNo}
            photoByte={primaryInfo?.Photobyte}
          />
        </Grid2>
        <Grid2 xs={12} lg={5}>
          <QuickLinksCard />
        </Grid2>
      </Grid2>

      <DetailsCard data={personalInfo} title="Personal Details" />
      <DetailsCard data={officialInfo} title="Official Details" />
    </>
  );
};

export default Profile;

import { Box, Button, Chip, Typography } from "@mui/material";
import useStore from "../store/store";

const SocialConnectCard = () => {
  const { setDialogTitle, setShowDialog, setDialogVariant } = useStore();

  const handleClick = () => {
    setDialogTitle("Birthday Wish");
    setDialogVariant("sendWishes");
    setShowDialog(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "16px",
        borderRadius: "10px",
        backgroundColor: "background.paper",
        border: "1px solid",
        borderColor: "primary.main",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <img
          src="https://picsum.photos/200/200"
          alt="profile"
          style={{ borderRadius: "50%", width: "50px", height: "50px" }}
        />
        <Typography
          variant="h5"
          fontSize={"24px"}
          sx={{
            // text ellipse
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "200px",
          }}
        >
          Mr. John Doe
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
        }}
      >
        <Typography
          variant="h5"
          fontSize={"28px"}
          fontWeight={600}
          color={"#E06674"}
        >
          24
        </Typography>
        <Typography variant="h5" fontSize={"16px"}>
          MARCH
        </Typography>
        <Chip label={"Birthday"} variant="filled" />
      </Box>

      <Button
        variant="contained"
        size="small"
        sx={{
          alignSelf: "flex-start",
          backgroundColor: "#04B9AE",
          margin: 0,
          marginTop: "6px",
          "&:hover": {
            backgroundColor: "#04B9AE",
          },
        }}
        onClick={handleClick}
      >
        Wish Him
      </Button>
    </Box>
  );
};

export default SocialConnectCard;

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStore from "../store/store";

const Logout = () => {
  const navigate = useNavigate();
  const { setShowDialog, logout } = useStore();

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleLogout = () => {
    // remove multiple local storage items
    localStorage.removeItem("authToken");
    logout();
    setShowDialog(false);
    navigate("/", { replace: true });
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        sx={{
          margin: "8px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        sx={{
          margin: "8px",
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "error.dark",
          },
        }}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </>
  );
};

export default Logout;

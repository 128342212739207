import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MessageCard from "./MessageCard";
import { CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MessagesContainer = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");



  if (isSmallScreen) {
    return (
        <>{
              /*  <SwipeableDrawer
              onOpen = { toggle }
          onClose={toggle}
              open={showLatestMessages}
              anchor="bottom"
        >
              <Paper
                  elevation={12}
                  // prevent propagation of click events
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      padding: "12px 16px",
                      borderRadius: "14px",
                      backgroundColor: "white",
                  }}
              >
                  <Box
                      sx={{
                          width: "32px",
                          height: "4px",
                          backgroundColor: "lightgray",
                          marginX: "auto",
                      }}
                  ></Box>
                  <Box
                      sx={{
                          display: "flex",
                          alignItems: "center",
                      }}
                  >
                      <Typography
                          component={"h2"}
                          sx={{
                              fontSize: "20px",
                              fontWeight: 500,
                              color: "primary.main",
                          }}
                      >
                          Latest Messages
                      </Typography>

                      <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          sx={{
                              marginLeft: "6px",
                          }}
                          onClick={() => navigate("/dashboard/messages")}
                      >
                          See all
                      </Button>

                      <Box sx={{ flexGrow: 1 }}></Box>

                      <IconButton onClick={() => setShowLatestMessages(false)}>
                          <CloseOutlined />
                      </IconButton>
                  </Box>
                  <Box
                      sx={{
                          maxHeight: "350px",
                          overflowY: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                      }}
                  >
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                      <MessageCard />
                  </Box>
              </Paper>
          </SwipeableDrawer > */
        }
       
      </>
    );
  }
  return (
    <>
      <Paper
        elevation={12}
        // prevent propagation of click events
        onClick={(e) => e.stopPropagation()}
        sx={{
          display: 1==1 ? "none" : "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "12px 16px",
          borderRadius: "14px",
          backgroundColor: "white",
          position: "absolute",
          top: "110%",
          right: { md: 0 },
          left: { xs: 0, md: "inherit" },
          width: { xs: "340px", md: "420px" },
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            width: "32px",
            height: "4px",
            backgroundColor: "lightgray",
            marginX: "auto",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "",
            alignItems: "center",
          }}
        >
          <Typography
            component={"h2"}
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "primary.main",
            }}
          >
            Latest Messages
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{
              marginLeft: "6px",
            }}
            onClick={() => navigate("/dashboard/messages")}
          >
            See all
          </Button>

          <Box sx={{ flexGrow: 1 }}></Box>

          <IconButton onClick={() =>{}}> {/*setShowLatestMessages(false)}>*/}
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: "350px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
        </Box>
      </Paper>
    </>
  );
};

export default MessagesContainer;

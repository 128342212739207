import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface SingleNavLinkProps {
  text: string;
  icon: ReactElement;
  route: string;
  handleDrawerToggle?: () => void;
}

const SingleNavLink = ({
  text,
  icon,
  route,
  handleDrawerToggle,
}: SingleNavLinkProps) => {
  const { pathname } = useLocation();
  const isActive = pathname === "/secured/" + route;
  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{
          marginX: "6px",
          transition: "all 0.3s ease-in-out",
          borderRadius: "10px",
          ...(isActive && {
            backgroundColor: "background.paper",
            color: "primary.main",
            "&:hover": { background: "white" },
          }),
        }}
        component={NavLink}
        to={route}
        onClick={handleDrawerToggle && handleDrawerToggle}
      >
        <ListItemIcon
          sx={{ minWidth: 40, color: isActive ? "primary.main" : "white" }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default SingleNavLink;

// available pages for search

export interface PageType {
  name: string;
  link: string;
}
export const pages: PageType[] = [
  { name: "Dashboard", link: "/secured/dashboard" },
  { name: "Comp Off", link: "/secured/my-links/comp-off" },
  { name: "Leave Application", link: "/secured/my-links/leave-application" },
  { name: "Miss Punch", link: "/secured/my-links/miss-punch" },
  {
    name: "Telephone Extensions",
    link: "/secured/my-links/telephone-extensions",
  },
  {
    name: "Grievance Redressal",
    link: "/secured/my-links/grievance-redressal",
  },
  {
    name: "Caste Discrimination",
    link: "/secured/my-links/caste-discrimination",
  },
  { name: "Holidays List", link: "/secured/my-links/holiday-list" },
  { name: "My Profile", link: "/secured/my-links/my-profile" },
  { name: "My Attendance", link: "/secured/my-links/my-attendance" },
  { name: "Academic Calendar", link: "/secured/my-links/academic-calendar" },
  { name: "Social Connect", link: "/secured/my-links/social-connect" },
  { name: "Women Harassment", link: "/secured/my-links/women-harassment" },
  { name: "Student Enrollment", link: "/secured/hr/student-enrollment" },
  { name: "Staff Enrollment", link: "/secured/hr/staff-enrollment" },
  { name: "ICard Requisition", link: "/secured/admissions/icard-requisition" },
  { name: "ICard Printing", link: "/secured/admissions/icard-printing" },
];

import React, { useState, useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import HeadDivider from "../components/ui/HeadDivider";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  RefreshOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useStore from "../store/store";
import { useDisplayMissPunch, useMissPunchRegister } from "../services/queries";
import {
  useSaveMissPunchMutation,
  useUpdateMissPunchMutation,
} from "../services/mutations";
import { MissPunchDataType, MissPunchFormData } from "../types/missPunch";

dayjs.extend(utc);
dayjs.extend(timezone);

const MissPunch: React.FC = () => {
  const [editingForm, setEditingForm] = useState(false);
  const [missPunchEntryId, setMissPunchEntryId] = useState("");
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const [fetchKey, setFetchKey] = useState<number>(0);
  const [entryDate, setEntryDate] = useState<dayjs.Dayjs>(dayjs());
  const [voucherNo, setVoucherNo] = useState<string>("");
  const [missPunchFor, setMissPunchFor] = useState<
    "In" | "Out" | "Both" | null
  >(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(1, "month")
  );
  const [toDate, setToDate] = useState<dayjs.Dayjs>(dayjs());

  const {
    setLoading,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
    setViewDetails,
  } = useStore();

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
  } = useForm<MissPunchFormData>({
    defaultValues: {
      entryID: missPunchEntryId,
      missPunchDate: "",
      description: "",
      missPunchFor: "",
      inTime: null,
      outTime: null,
    },
  });

  const {
    data: registerData,
    isLoading: registerLoading,
    refetch: refetchMissPunchRegister,
  } = useMissPunchRegister(fromDate.toString(), toDate.toString());

  const {
    data: singleMissPunchData,
    isLoading: displayLoading,
    refetch: refetchSingleMissPunch,
  } = useDisplayMissPunch(missPunchEntryId);

  const { isPending: saveLoading, mutateAsync: saveAsync } =
    useSaveMissPunchMutation();
  const { isPending: updateLoading, mutateAsync: updateAsync } =
    useUpdateMissPunchMutation();

  const onSaveHandler = async (data: MissPunchFormData) => {
    await saveAsync(data);
    refetchMissPunchRegister();
    reset();
  };

  const onUpdateHandler = async (data: MissPunchFormData) => {
    data.entryID = missPunchEntryId;
    await updateAsync(data);
    refetchMissPunchRegister();
    reset();
    setEditingForm(false);
    setMissPunchFor(null);
    setVoucherNo("");
    setEntryDate(dayjs());
  };

  useEffect(() => {
    setLoading(
      saveLoading || registerLoading || displayLoading || updateLoading
    );
  }, [saveLoading, updateLoading, registerLoading, displayLoading, setLoading]);

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = useMemo(() => {
    if (!registerData) return 0;
    return Math.max(
      0,
      rowsPerPage -
        Math.min(rowsPerPage, registerData.length - page * rowsPerPage)
    );
  }, [registerData, rowsPerPage, page]);

  const handleEditMissPunch = (id: string) => {
    setMissPunchEntryId(id);
    setFetchKey((prev) => prev + 1);
    setEditingForm(true);
    setTextAreaFocused(true);
    window.scrollTo(0, 0);
  };

  const handleDisplayMissPunch = (id: string) => {
    setEditingForm(false);
    setMissPunchEntryId(id);
    setFetchKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (singleMissPunchData && Object.keys(singleMissPunchData).length > 0) {
      if (editingForm) {
        const data: MissPunchDataType = singleMissPunchData[0];
        const {
          Reason,
          MisspunchFor,
          DateInTime,
          DateOutTime,
          VoucherNo,
          EntryDate,
        } = data;
        setVoucherNo(VoucherNo.toString());
        setEntryDate(dayjs(EntryDate));
        setValue("description", Reason);
        setValue("missPunchFor", MisspunchFor);
        setMissPunchFor(MisspunchFor as "In" | "Out" | "Both");
        setValue("inTime", DateInTime ? dayjs(DateInTime) : null);
        setValue("outTime", DateOutTime ? dayjs(DateOutTime) : null);
        window.scrollTo(0, 0);
      } else {
        setViewDetails(singleMissPunchData);
        setDialogTitle("Miss Punch Details");
        setDialogVariant("viewDetails");
        setShowDialog(true);
      }
    }
  }, [
    singleMissPunchData,
    editingForm,
    setValue,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
  ]);

  useEffect(() => {
    if (fetchKey > 0) {
      refetchSingleMissPunch();
    }
  }, [fetchKey, refetchSingleMissPunch]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}
      >
        Miss Punch Application
      </Typography>
      <HeadDivider heading="Form" />
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: "20px 16px",
              borderColor: (theme) => theme.palette.primary.main,
              height: "100%",
            }}
          >
            {editingForm && (
              <Alert
                variant="standard"
                severity="info"
                sx={{ marginBottom: 2 }}
              >
                <Typography variant="body2">
                  {`Editing EntryId: ${missPunchEntryId}`}
                </Typography>
              </Alert>
            )}

            <form
              onSubmit={handleSubmit(
                editingForm ? onUpdateHandler : onSaveHandler
              )}
            >
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={6}>
                  <TextField label="Voucher No." value={voucherNo} disabled />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <DatePicker
                    value={entryDate}
                    readOnly
                    label="Entry Date"
                    format="DD/MM/YYYY"
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="missPunchDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={field.value || null}
                        format="DD/MM/YYYY"
                        label="Miss Punch Date"
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <FormLabel>Miss Punch For</FormLabel>
                  <RadioGroup row value={missPunchFor}>
                    <FormControlLabel
                      value="In"
                      control={
                        <Radio
                          {...register("missPunchFor")}
                          onChange={(e) =>
                            setMissPunchFor(
                              e.target.value as "In" | "Out" | "Both"
                            )
                          }
                          required
                        />
                      }
                      label="In Time"
                    />
                    <FormControlLabel
                      value="Out"
                      control={
                        <Radio
                          {...register("missPunchFor")}
                          onChange={(e) =>
                            setMissPunchFor(
                              e.target.value as "In" | "Out" | "Both"
                            )
                          }
                          required
                        />
                      }
                      label="Out Time"
                    />
                    <FormControlLabel
                      value="Both"
                      control={
                        <Radio
                          {...register("missPunchFor")}
                          onChange={(e) =>
                            setMissPunchFor(
                              e.target.value as "In" | "Out" | "Both"
                            )
                          }
                          required
                        />
                      }
                      label="Both"
                    />
                  </RadioGroup>
                </Grid2>
                <Grid2
                  xs={12}
                  md={6}
                  sx={{
                    display:
                      missPunchFor === "In" || missPunchFor === "Both"
                        ? "block"
                        : "none",
                  }}
                >
                  <Controller
                    control={control}
                    name="inTime"
                    render={({ field }) => (
                      <DateTimePicker
                        {...field}
                        value={field.value || null}
                        format="DD/MM/YYYY HH:mm"
                        label="In Time"
                        slotProps={{
                          textField: {
                            required:
                              missPunchFor === "In" || missPunchFor === "Both",
                          },
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2
                  xs={12}
                  md={6}
                  sx={{
                    display:
                      missPunchFor === "Out" || missPunchFor === "Both"
                        ? "block"
                        : "none",
                  }}
                >
                  <Controller
                    control={control}
                    name="outTime"
                    render={({ field }) => (
                      <DateTimePicker
                        {...field}
                        value={field.value || null}
                        format="DD/MM/YYYY HH:mm"
                        label="Out Time"
                        slotProps={{
                          textField: {
                            required:
                              missPunchFor === "Out" || missPunchFor === "Both",
                          },
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField
                    focused={textAreaFocused}
                    required
                    multiline
                    minRows={3}
                    label="Reason"
                    {...register("description")}
                    onBlur={() => {
                      getValues("description") === "" &&
                        setTextAreaFocused(false);
                    }}
                  />
                </Grid2>
              </Grid2>

              {editingForm ? (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      setValue("description", "");
                      setEditingForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      reset();
                      setEntryDate(dayjs());
                      setMissPunchFor(null);
                      setEditingForm(false);
                      setVoucherNo("");
                      reset({
                        entryID: "",
                        description: "",
                        missPunchDate: "",
                        missPunchFor: "",
                        inTime: null,
                        outTime: null,
                      });
                    }}
                  >
                    New
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Save
                  </Button>
                </>
              )}
            </form>
          </Box>
        </Grid2>
      </Grid2>

      {/* Approval Flow */}
      <Typography
        sx={{
          paddingY: 1,
          color: (theme) => theme.palette.primary.main,
          fontWeight: "bold",
        }}
      >
        Approval Flow:
      </Typography>

      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Authority</TableCell>
              <TableCell>Approval Date</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1.</TableCell>
              <TableCell>HOD - Mr. Arun D. Sonar</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.</TableCell>
              <TableCell>HOD - Dr. L. K. Wadhwa</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ height: 24 }} />
      <HeadDivider heading="History" />
      <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
        {/* set default from and to dates */}
        <DatePicker
          label="From"
          value={fromDate ?? ""}
          format="DD/MM/YYYY"
          onChange={(newValue) => newValue && setFromDate(newValue)}
        />
        <DatePicker
          label="To"
          value={toDate ?? ""}
          format="DD/MM/YYYY"
          onChange={(newValue) => newValue && setToDate(newValue)}
        />
      </Box>
      <Button onClick={() => refetchMissPunchRegister()}>
        Refresh <RefreshOutlined />
      </Button>
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Entry ID</TableCell>
              <TableCell>EntryDate</TableCell>
              <TableCell>MissPunchDate</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>MissPunch Status</TableCell>
              <TableCell>Approval Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registerData &&
              registerData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow key={row.EntryID}>
                    <TableCell>{row.EntryID}.</TableCell>
                    <TableCell>{row.EntryDate}</TableCell>
                    <TableCell>{row.MisspunchDate}</TableCell>
                    <TableCell>{row.Reason}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell>{row.MisspunchStatus}</TableCell>
                    <TableCell>{row.ApprovalStatus}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "center",
                        margin: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <IconButton
                        onClick={() => handleDisplayMissPunch(row.EntryID)}
                      >
                        <RemoveRedEyeOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditMissPunch(row.EntryID)}
                      >
                        <EditOutlined color="primary" />
                      </IconButton>
                      <IconButton>
                        <DeleteOutlineOutlined color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={registerData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default MissPunch;

import {
  Box,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import HeadDivider from "../components/ui/HeadDivider";
import { useForm } from "react-hook-form";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";

interface RequisitionFormValues {
  requestNo: string;
  requestDate: Date | null;
  requestBy: string;
  requestFor: string;
  validityPeriod: string;
  academicYear: Date | null;
  course: string;
  class: string;
  currentStatus: string;
  classStatus: string;
  remarks: string;
}

function ICardRequisition() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<RequisitionFormValues>({
    defaultValues: {
      requestNo: "",
      requestDate: null,
      requestBy: "",
      requestFor: "",
      validityPeriod: "",
      academicYear: null,
      course: "",
      class: "",
      currentStatus: "",
      classStatus: "",
      remarks: "",
    },
  });

  const onRequisitionSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <Grid2 container spacing={2} my={1}>
        {/* ICard Requisition Section */}
        <form onSubmit={handleSubmit(onRequisitionSubmit)}>
          <Grid2 xs={12} md={12}>
            <HeadDivider heading="ICard Requisition" />
            <Box
              sx={{
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: 3,
                padding: 3,
                borderColor: (theme) => theme.palette.primary.main,
              }}
            >
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Request Number"
                    value={watch("requestNo")}
                    {...register("requestNo")}
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Request By"
                    value={watch("requestBy")}
                    {...register("requestBy", {
                      required: true,
                    })}
                    error={!!errors.requestBy}
                    // helperText={errors.staffName?.message?.toString()}
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <DatePicker
                    format="YYYY"
                    label="Request Date"
                    views={["year"]}
                    {...register("requestDate")}
                    onChange={() => {}}
                  />
                </Grid2>

                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Request For"
                    value={watch("requestFor")}
                    {...register("requestFor")}
                  />
                </Grid2>
                <Grid2 xs={6} md={4}>
                  <TextField
                    label="Validity Period"
                    select
                    value={watch("validityPeriod")}
                    {...register("validityPeriod")}
                  >
                    <MenuItem value="23-24">2023-2024</MenuItem>
                  </TextField>
                </Grid2>
                <Grid2 xs={6} md={4}>
                  <DatePicker
                    format="YYYY"
                    label="Academic Year"
                    views={["year"]}
                    {...register("academicYear")}
                    onChange={() => {}}
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Course"
                    select
                    value={watch("course")}
                    {...register("course", {
                      required: true,
                    })}
                    error={!!errors.course}
                  >
                    <MenuItem value="MCA">MCA</MenuItem>
                    <MenuItem value="MBA">Evening</MenuItem>
                  </TextField>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Class"
                    value={watch("class")}
                    {...register("class", {
                      required: true,
                    })}
                    error={!!errors.class}
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Current Status"
                    select
                    value={watch("currentStatus")}
                    {...register("currentStatus", {
                      required: true,
                    })}
                    error={!!errors.currentStatus}
                  >
                    <MenuItem value="Science">Science</MenuItem>
                    <MenuItem value="Mathematics">Mathematics</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="History">History</MenuItem>
                  </TextField>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <TextField
                    label="Class Status"
                    select
                    value={watch("classStatus")}
                    {...register("classStatus", {
                      required: true,
                    })}
                    error={!!errors.classStatus}
                  >
                    <MenuItem value="Science">Science</MenuItem>
                    <MenuItem value="Mathematics">Mathematics</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="History">History</MenuItem>
                  </TextField>
                </Grid2>
                <Grid2 xs={12} md={8}>
                  <TextField
                    label="Remarks"
                    value={watch("remarks")}
                    {...register("remarks", {
                      required: true,
                    })}
                    error={!!errors.class}
                  />
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </form>
      </Grid2>

      {/* HISTORY */}
      <HeadDivider heading="History" />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Blood Group</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Current Address</TableCell>
              <TableCell>Permanent Address</TableCell>
              <TableCell>Student ID</TableCell>
              <TableCell>Validity Period</TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {leaveData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}.</TableCell>
                  <TableCell>{row.ApplicationDate}</TableCell>
                  <TableCell>{row.LeaveDate}</TableCell>
                  <TableCell>{row.Days}</TableCell>
                  <TableCell>{row.LeaveType}</TableCell>
                  <TableCell>{row.Description}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>
                    <Button variant="text" sx={{ p: 0 }}>
                      View
                    </Button>
                    <IconButton color="error" sx={{ p: 0 }}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={20} />
              </TableRow>
            )}
          </TableBody> */}
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={leaveData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </>
  );
}

export default ICardRequisition;

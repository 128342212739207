import axios from "axios";

export const saveStudentEnrollment = async (formData: any) => {
  try {
    const url = "https://1600.sdctest.in/StudentEnrollment/Staff";

    const response = await axios.post(url, formData);
    return response.data;
  } catch (error) {
    console.error("Error saving MissPunch:", error);
    throw new Error("Error saving MissPunch");
  }
};

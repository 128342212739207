import {
  DescriptionOutlined,
  LogoutOutlined,
  NotificationsOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MobilePageSearchBar from "./MobilePageSearchBar";
import useStore from "../../store/store";

const MobileToolbar = () => {
  const navigate = useNavigate();
  const {
    name,
    profileImage,
    expandMobileSearchBar,
    setShowDialog,
    setDialogTitle,
    setDialogVariant,
  } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: expandMobileSearchBar ? "100%" : "auto",
          }}
        >
          <MobilePageSearchBar />
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              ml: 2,
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 36, height: 36 }}
              src={`data:image/png;base64,${profileImage}`}
              alt={name}
            ></Avatar>
          </IconButton>
        </Box>
        {/* Menu */}
        <Menu
          sx={{
            "& .MuiMenu-list": {
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            },
          }}
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* Menu Items */}
          <MenuItem
            onClick={() => {
              navigate("/dashboard/my-profile");
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <PermIdentityOutlined />
            Profile
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/dashboard/my-notifications");
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Badge
              badgeContent={4}
              color="warning"
              sx={{
                "& .MuiBadge-dot": {
                  width: "10px",
                  height: "10px",
                },
              }}
            >
              <NotificationsOutlined />
            </Badge>
            Notifications
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <DescriptionOutlined />
            Todo List
          </MenuItem>

          <Divider
            sx={{
              "&.MuiDivider-root": {
                margin: "0 0",
              },
            }}
          />

          <MenuItem
            onClick={() => {
              setShowDialog(true);
              setDialogTitle("Logout");
              setDialogVariant("logout");
              handleClose();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <LogoutOutlined />
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    </>
  );
};

export default MobileToolbar;

import {
  Box,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import HeadDivider from "../components/ui/HeadDivider";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { AccessTimeOutlined } from "@mui/icons-material";
import { ChangeEvent, useState, MouseEvent, useEffect } from "react";
import SelectInput from "../components/ui/SelectInput";
import AttendanceChart from "../components/ui/LineChart";
import OverviewChart from "../components/attendance/OverviewChart";
import { useQuery } from "@tanstack/react-query";
import { GetAttendance } from "../services/MyAttendance";
import useStore from "../store/store";

interface TableRow {
  date: string;
  status: string;
  shiftTime: string;
  inTime: string;
  outTime: string;
  totalHours: string;
  lateInDifference: string | null;
}

const MyAttendance = () => {
  const { setLoading } = useStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [selectYear, setSelectYear] = useState("2024");
  const [selectMonth, setSelectMonth] = useState("January");
  const {
    data: attendanceData,
    isLoading,
  } = useQuery({
    queryKey: ["attendance", selectYear, selectMonth],
    queryFn: () => {
      return GetAttendance("649", selectMonth, selectYear);
    },
  });

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      attendanceData?.tableData?.length || rowsPerPage - page * rowsPerPage
    );

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectMonth(event.target.value);
  };
  const handleYearChange = (event: SelectChangeEvent) => {
    setSelectYear(event.target.value);
  };

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const yearOptions = ["2020", "2021", "2022", "2023", "2024"];

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}
      >
        My Attendance
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={8}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              borderColor: (theme) => theme.palette.primary.main,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between ",
            }}
          >
            <AttendanceChart
              xaxis={attendanceData?.graphData?.xaxis ?? []}
              yaxis={attendanceData?.graphData?.yaxis ?? []}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginLeft: "24px",
                margin: 2,
              }}
            >
              <SelectInput
                value={selectMonth}
                options={monthOptions}
                onChange={handleMonthChange}
              />
              <SelectInput
                value={selectYear}
                options={yearOptions}
                onChange={handleYearChange}
              />
            </Box>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <OverviewChart
            year={selectYear}
            yearOptions={yearOptions}
            handleYearChange={handleYearChange}
            yearSelectionPresent={false}
            title="Monthly Attendance"
            label1="Present"
            label2="Absent"
            percentage={
              attendanceData?.MonthlyAttendancePieChartData
                ?.presentPercentage ?? "-"
            }
            value1={
              attendanceData?.MonthlyAttendancePieChartData?.presentCount ?? 0
            }
            value2={
              attendanceData?.MonthlyAttendancePieChartData?.absentCount ?? 0
            }
          />
        </Grid2>
      </Grid2>

      <Box sx={{ height: 24 }} />
      <HeadDivider heading="Details" />

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
          <Table sx={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Shift Time</TableCell>
                <TableCell>In Time</TableCell>
                <TableCell>Out Time</TableCell>
                <TableCell>Total Hours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(
                attendanceData?.tableData?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ) ?? []
              ).map((row: TableRow) => (
                <TableRow key={row.date}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "350px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textWrap: "nowrap",
                    }}
                  >
                    <Tooltip title={row.status}>
                      <span>{row.status}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.shiftTime}</TableCell>
                  <TableCell>
                    {row.inTime}{" "}
                    {row.lateInDifference && (
                      <Box
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          color: (theme) => theme.palette.error.light,
                          backgroundColor: (theme) =>
                            theme.palette.error.light + "20",
                          padding: "2px 4px",
                          borderRadius: 1,
                          marginY: "-4px",
                          marginLeft: "4px",
                          transform: "translateY(3px)",
                          lineHeight: 1,
                        }}
                      >
                        <AccessTimeOutlined
                          sx={{
                            paddintTop: 3,
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        />
                        <Typography fontSize="12px">
                          {row.lateInDifference}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>{row.outTime}</TableCell>
                  <TableCell>{row.totalHours}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 41.5 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 31]}
            component="div"
            count={attendanceData?.tableData?.length ?? 0} // Assign a default value of 0 if attendanceData is undefined
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={4}>
          <OverviewChart
            title="Monthly InTime"
            year={selectYear}
            yearOptions={yearOptions}
            handleYearChange={handleYearChange}
            yearSelectionPresent={false}
            label1="On Time"
            label2="Late"
            percentage={
              attendanceData?.MonthlyInTimePieChartData?.onTimePercentage ?? "-"
            }
            value1={attendanceData?.MonthlyInTimePieChartData?.onTimeCount ?? 0}
            value2={attendanceData?.MonthlyInTimePieChartData?.lateCount ?? 0}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <OverviewChart
            title="Yearly InTime"
            year={selectYear}
            yearOptions={yearOptions}
            handleYearChange={handleYearChange}
            yearSelectionPresent={false}
            label1="On Time"
            label2="Late"
            percentage="89%"
            value1={100}
            value2={20}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <OverviewChart
            title="Yearly Attendance"
            year={selectYear}
            yearOptions={yearOptions}
            handleYearChange={handleYearChange}
            yearSelectionPresent={false}
            label1="Present"
            label2="Absent"
            percentage="89%"
            value1={100}
            value2={20}
          />
        </Grid2>
      </Grid2>
    </div>
  );
};

export default MyAttendance;

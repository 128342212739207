import { StateCreator } from "zustand";

// Define the AuthState type including InstituteID and StaffID
type AuthState = {
  isAuthenticated: boolean;
  token: string | null;
  userId: number | null;
  instituteId: number | null;
  staffId: number | null;
};

// Define the AuthActions type including actions for InstituteID and StaffID
type AuthActions = {
  login: (
    token: string,
    userId: number,
    instituteId: number,
    staffId: number
  ) => void;
  logout: () => void;
  setToken: (token: string) => void;
  setUserId: (userId: number) => void;
  setInstituteId: (instituteId: number) => void;
  setStaffId: (staffId: number) => void;
};

// Combine AuthState and AuthActions into a single AuthSlice type
export type AuthSlice = AuthState & AuthActions;

// Create the auth slice using the StateCreator
export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  // Initialize the authentication state
  isAuthenticated: false,
  token: null,
  userId: null,
  instituteId: null,
  staffId: null,

  // Define the login action to set authentication details
  login: (
    token: string,
    userId: number,
    instituteId: number,
    staffId: number
  ) =>
    set(() => ({
      isAuthenticated: true,
      token,
      userId,
      instituteId,
      staffId,
    })),

  // Define the logout action to clear authentication details
  logout: () =>
    set(() => ({
      isAuthenticated: false,
      token: null,
      userId: null,
      instituteId: null,
      staffId: null,
    })),

  // Define setToken to update the token separately
  setToken: (token: string) => set(() => ({ token })),

  // Define setUserId to update the userId separately
  setUserId: (userId: number) => set(() => ({ userId })),

  // Define setInstituteId to update the instituteId separately
  setInstituteId: (instituteId: number) => set(() => ({ instituteId })),

  // Define setStaffId to update the staffId separately
  setStaffId: (staffId: number) => set(() => ({ staffId })),
});

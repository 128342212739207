import { Box } from '@mui/material'

const Textarea = ({ placeholder }: { placeholder: string }) => {
  return (
    <>
      <Box
        component={'textarea'}
        rows={5}
        placeholder={placeholder}
        sx={{
          width: '100%',
          fontSize: '16px',
          padding: '8px',
          borderRadius: '5px',
          border: '2px solid',
          borderColor: 'primary.light',
          outline: 'none',
          resize: 'none',
          '&:focus': {
            borderColor: 'primary.main',
          },
        }}
      />
    </>
  )
}

export default Textarea

import { TextField } from '@mui/material'

const ChangePasswordForm = () => {
  return (
    <>
      <TextField
        autoFocus
        autoComplete='off'
        required
        margin='dense'
        id='name'
        name='email'
        label='Current Password'
        type='email'
        fullWidth
        variant='outlined'
      />
      <TextField
        autoFocus
        autoComplete='off'
        required
        margin='dense'
        id='name'
        name='email'
        label='New Password'
        type='email'
        fullWidth
        variant='outlined'
      />
      <TextField
        autoFocus
        autoComplete='off'
        required
        margin='dense'
        id='name'
        name='email'
        label='Re-enter New Password'
        type='email'
        fullWidth
        variant='outlined'
      />
    </>
  )
}

export { ChangePasswordForm }

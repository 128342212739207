import { Box, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import HolidayChip from './HolidayChip'

interface HolidayCardProps {
  month: string
  holidays: Array<{
    day: number
    label: string
    weekday: string
  }>
}

const HolidayCard = ({ month, holidays }: HolidayCardProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderRadius: 3,
        padding: 2,
        borderColor: (theme) => theme.palette.primary.main,
        height: '100%',
      }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: 20,
          fontWeight: 'bold',
          color: (theme) => theme.palette.secondary.main,
          paddingBottom: 2,
        }}>
        {month}
      </Typography>
      <Grid2 container spacing='8px'>
        {holidays.map((holiday, key) => {
          return (
            <Grid2 key={key} xs={12} lg={6}>
              <HolidayChip
                day={holiday.day}
                label={holiday.label}
                weekday={holiday.weekday}
              />
            </Grid2>
          )
        })}
      </Grid2>
    </Box>
  )
}

export default HolidayCard

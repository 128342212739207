import Box from '@mui/material/Box'
import useOnlineStatus from '../hooks/useOnlineStatus'
import { Typography } from '@mui/material'

const NetworkIndicator = () => {
  const isOnline = useOnlineStatus()
  // const isOnline = true
  return (
    <Box
      sx={{
        transform: isOnline ? 'scaleY(0)' : 'scaleY(100%)',
        backgroundColor: (theme) =>
          isOnline ? theme.palette.success.main : theme.palette.warning.main,
        color: 'white',
        paddingY: 1,
        transition: 'background-color 0.1s, transform 0.5s',
        transformOrigin: 'top',
        transitionDelay: '0s, 1s',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100vw',
        zIndex: 2000,
      }}>
      <Typography textAlign='center'>
        {isOnline ? 'Back Online!' : "You're offline"}
      </Typography>
    </Box>
  )
}

export default NetworkIndicator

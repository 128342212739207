import {
  Box,
  Button,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import HeadDivider from '../components/ui/HeadDivider'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ChangeEvent, useState, MouseEvent } from 'react'
import LabelledSelectInput from '../components/ui/LabelledSelectInput'

const GrievanceRedressal = () => {
  // Dummy data for displaying in history table
  const rows = [
    {
      id: 1,
      ComplaintNo: 1,
      ComplaintDate: '03/03/2018',
      ComplaintAgainst: 'Institutional Student',
      ComplaintBy: 'Staff',
      ComplaintContents: 'Just Test.....',
      ActionTaken: 'NO',
    },
  ]

  // State variables for history table pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const [complaintAgainst, setComplaintAgainst] = useState('')
  const handleComplaintAgainstChange = (event: SelectChangeEvent) => {
    setComplaintAgainst(event.target.value)
  }
  const complaintAgainstOptions = [
    'Institutional Student',
    'Institutional Staff',
    'Infrastructural',
  ]

  return (
    <div>
      <Typography
        variant='h1'
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}>
        Grievance Redressal
      </Typography>
      <HeadDivider heading='Form' />
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Box
            sx={{
              backgroundColor: 'white',
              border: '1px solid',
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
              height: '100%',
            }}>
            <form>
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={6}>
                  <TextField label='Complaint No.' />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <TextField label='Complaint Date' />
                </Grid2>
                <Grid2 xs={12} md={6}>
                   <LabelledSelectInput
                    name='Complaint Against'
                    label='Complaint Against'
                    options={complaintAgainstOptions}
                    value={complaintAgainst}
                    onChange={handleComplaintAgainstChange}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <TextField label='Complaint By' value='Staff' disabled />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField multiline minRows={3} label='Complaint Content' />
                </Grid2>
              </Grid2>
            </form>
          </Box>
        </Grid2>
      </Grid2>
      <Box sx={{ height: 24 }} />
      <Button>Submit</Button>
      <Box sx={{ height: 24 }} />
      <HeadDivider heading='History' />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Complaint No.</TableCell>
              <TableCell>Complaint Date</TableCell>
              <TableCell>Complaint Against</TableCell>
              <TableCell>Complaint By</TableCell>
              <TableCell>Complaint Content</TableCell>
              <TableCell>Action Taken</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.ComplaintNo}</TableCell>
                  <TableCell>{row.ComplaintDate}</TableCell>
                  <TableCell>{row.ComplaintAgainst}</TableCell>
                  <TableCell>{row.ComplaintBy}</TableCell>
                  <TableCell>{row.ComplaintContents}</TableCell>
                  <TableCell>{row.ActionTaken}</TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}

export default GrievanceRedressal

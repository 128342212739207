import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { OfficialDataType, PersonalDataType } from "../../types/profile";

interface Props {
  data: PersonalDataType | OfficialDataType | undefined;
  title: string;
}

const DetailsCard = ({ data, title }: Props) => {
  return (
    <Box
      sx={{
        marginTop: "20px",
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "0 10px 10px 10px",
        position: "relative",
        border: "1px solid",
        borderColor: "primary.main",
      }}
    >
      <Box
        component={"p"}
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          backgroundColor: "primary.main",
          color: "white",
          padding: "5px 10px",
          borderRadius: "0 0 10px 0",
        }}
      >
        {title}
      </Box>

      <Grid2 container columnSpacing={2} rowSpacing={1} marginTop={"24px"}>
        {data &&
          Object.keys(data).map((key, index) => {
            return (
              <Grid2 key={index} xs={12} md={6} display={"flex"}>
                <Box component={"p"}>
                  <Box
                    component={"span"}
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "primary.main",
                    }}
                  >
                    {key}:
                  </Box>
                  <Box
                    component={"span"}
                    sx={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "text.primary",
                    }}
                  >
                    {data[key]}
                  </Box>
                </Box>
              </Grid2>
            );
          })}
      </Grid2>
    </Box>
  );
};

export default DetailsCard;

import {
  CalendarMonthOutlined,
  DriveFileRenameOutlineOutlined,
  MessageOutlined,
  MoveToInboxOutlined,
  WorkOffOutlined,
} from "@mui/icons-material";
import DashboardQuickLink from "../components/dashboard/QuickLink";
import { Box, Typography, useTheme } from "@mui/material";
import AttendanceChart from "../components/ui/LineChart";
import { useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import SelectInput from "../components/ui/SelectInput";
import Lectures from "../components/dashboard/Lectures";
import NoticesAndCirculars from "../components/dashboard/NoticesAndCirculars";
import Calendar from "../components/AcademicCalendar";
import SocialsCard from "../components/dashboard/SocialsCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import HeadDivider from "../components/ui/HeadDivider";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FeeReceipt from "../receipts/FeeReceipt";

import { GetAttendanceGraphData } from "../services/MyAttendance";
import { useQuery } from "@tanstack/react-query";

const Dashboard = () => {
  const theme = useTheme();
  const quickLinks = [
    {
      icon: <CalendarMonthOutlined />,
      title: "My Attendance",
      href: "/secured/my-links/my-attendance",
    },
    {
      icon: <DriveFileRenameOutlineOutlined />,
      title: "Leave Application",
      href: "/secured/my-links/leave-application",
    },
    {
      icon: <WorkOffOutlined />,
      title: "Comp Off Credit",
      href: "/secured/my-links/comp-off",
    },
    {
      icon: <MoveToInboxOutlined />,
      title: "Grievances",
      href: "/secured/my-links/grievance-redressal",
    },
    {
      icon: <MessageOutlined htmlColor="black" />,
      title: "Messages",
      href: "/secured/my-links/messages",
    },
  ];
  const yearOptions: string[] = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
  ];
  const [year, setYear] = useState(yearOptions[0]);

  const monthOptions: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [month, setMonth] = useState(monthOptions[0]);

  const handleSelectYear = (event: SelectChangeEvent) => {
    setYear(event.target.value);
  };
  const handleSelectMonth = (event: SelectChangeEvent) => {
    setMonth(event.target.value);
  };

  const { data: attendanceGraphData } = useQuery({
    queryKey: ["attendance", year, month],
    queryFn: () => {
      return GetAttendanceGraphData("649", month, year);
    },
  });

  return (
    <>
      <Grid2 container spacing={2}>
        {quickLinks.map((link, key) => (
          <Grid2 key={key} xs={6} md={4} lg={2.4}>
            <DashboardQuickLink
              icon={link.icon}
              title={link.title}
              href={link.href}
            />
          </Grid2>
        ))}
      </Grid2>

      <Box
        component={"section"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "28px",
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }}
      >
        {/* Left */}
        <Box component={"section"} sx={{ flexGrow: 1, width: "100%" }}>
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "background.paper",
              borderRadius: "10px",
              // height: '300px',
              border: "1.5px solid",
              borderColor: "primary.main",
            }}
          >
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color={"primary.main"}
                fontSize={"16px"}
              >
                Monthly Attendance Report
              </Typography>
              <Box
                component={"div"}
                sx={{
                  zIndex: 1,
                  display: "flex",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                <SelectInput
                  options={monthOptions}
                  value={month}
                  onChange={handleSelectMonth}
                />
                <SelectInput
                  options={yearOptions}
                  value={year}
                  onChange={handleSelectYear}
                />
              </Box>
            </Box>
            <AttendanceChart
              xaxis={attendanceGraphData?.xaxis || []}
              yaxis={attendanceGraphData?.yaxis || []}
            />
          </Box>
          {/* Lectures, Notices and Circulars */}
          <Box
            component={"section"}
            sx={{
              display: "flex",
              gap: "16px",
              marginTop: "16px",
              [theme.breakpoints.down("lg")]: { flexDirection: "column" },
            }}
          >
            <Box
              component={"div"}
              sx={{
                width: "50%",
                height: "325px",
                overflow: "auto",
                [theme.breakpoints.down("lg")]: { width: "100%" },
              }}
              bgcolor={"background.paper"}
              borderRadius={"10px"}
              border={"1px solid"}
              borderColor={"primary.main"}
            >
              <Box
                sx={{
                  backgroundColor: "background.paper",
                  padding: "12px 16px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <HeadDivider variant="small" heading="Today's Lectures" />
                <PDFDownloadLink document={<FeeReceipt />} fileName="some.pdf">
                  {({ loading }) =>
                    loading ? "Loading document..." : "Download Receipt"
                  }
                </PDFDownloadLink>
              </Box>
              <Lectures />
            </Box>
            <Box
              component={"div"}
              sx={{
                width: "50%",
                height: "325px",
                overflow: "auto",
                [theme.breakpoints.down("lg")]: { width: "100%" },
              }}
              bgcolor={"background.paper"}
              borderRadius={"10px"}
              border={"1px solid"}
              borderColor={"primary.main"}
            >
              <Box
                sx={{
                  backgroundColor: "background.paper",
                  padding: "12px 16px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <HeadDivider variant="small" heading="Notices and Circulars" />
              </Box>
              <NoticesAndCirculars />
            </Box>
          </Box>
        </Box>

        {/* Right */}
        <Box
          component={"aside"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            flexGrow: 0,
          }}
        >
          <Box
            component={"div"}
            sx={{
              backgroundColor: "background.paper",
              borderRadius: "10px",
              width: "100%",
              padding: "16px",
              border: "1.5px solid",
              borderColor: "primary.main",
            }}
          >
            <HeadDivider variant="small" heading="Academic Calendar" />
            <Box
              component={"div"}
              display={"flex"}
              gap={"10px"}
              marginTop={"4px"}
            >
              <Box
                component={"div"}
                bgcolor={"background.default"}
                padding={"10px"}
                borderRadius={"10px"}
                flexGrow={1}
              >
                <Box
                  component={"div"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"4px"}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "100%",
                      backgroundColor: "#E76474",
                    }}
                  />
                  <Box component={"p"} fontSize={"12px"}>
                    8 March
                  </Box>
                </Box>
                <Typography variant="subtitle2">Maha Shivratri</Typography>
              </Box>
              <Box
                component={"div"}
                bgcolor={"background.default"}
                padding={"10px"}
                borderRadius={"10px"}
                flexGrow={1}
              >
                <Box
                  component={"div"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"4px"}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "100%",
                      backgroundColor: "#28CDE4",
                    }}
                  />
                  <Box component={"p"} fontSize={"12px"}>
                    01 April
                  </Box>
                </Box>
                <Typography variant="subtitle2">In-Sem</Typography>
              </Box>
            </Box>
            {/* Calendar */}
            <Calendar />
          </Box>
          <SocialsCard />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

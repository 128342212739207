import {
  AnalyticsOutlined,
  CallOutlined,
  ComputerOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  EngineeringOutlined,
  FeedbackOutlined,
  GroupsOutlined,
  LayersOutlined,
  LinkOutlined,
  SchoolOutlined,
  GroupOutlined,
} from "@mui/icons-material";

export default [
  {
    text: "Dashboard",
    route: "dashboard",
    icon: <DashboardOutlined />,
  },
  {
    text: "My Links",
    route: "#",
    icon: <LinkOutlined />,
    nestedLinks: [
      {
        text: "My Profile",
        route: "my-links/my-profile",
      },
      {
        text: "My Attendance",
        route: "my-links/my-attendance",
      },
      {
        text: "Comp Off Credit",
        route: "my-links/comp-off",
      },
      {
        text: "Leave Application",
        route: "my-links/leave-application",
      },
      {
        text: "Change Password",
        route: "#",
        openFormDialog: true,
      },
      {
        text: "Holiday List",
        route: "my-links/holiday-list",
      },
      {
        text: "Miss Punch Application",
        route: "my-links/miss-punch",
      },
      {
        text: "Telephone Extensions",
        route: "my-links/telephone-extensions",
      },
      {
        text: "Grievance Redressal",
        route: "my-links/grievance-redressal",
      },
      {
        text: "Caste Discrimination",
        route: "my-links/caste-discrimination",
      },
      {
        text: "Academic Calendar",
        route: "my-links/academic-calendar",
      },
      {
        text: "Women Harassment",
        route: "my-links/women-harassment",
      },
    ],
  },

  {
    text: "Admissions",
    route: "#",
    icon: <LayersOutlined />,
    nestedLinks: [
      {
        text: "ICard Requisition",
        route: "admissions/icard-requisition",
      },
      {
        text: "ICard Printing",
        route: "admissions/icard-printing",
      },
    ],
  },
  {
    text: "HR",
    route: "#",
    icon: <GroupOutlined />,
    nestedLinks: [
      {
        text: "Student Enrollment",
        route: "hr/student-enrollment",
      },
      {
        text: "Staff Enrollment",
        route: "hr/staff-enrollment",
      },
    ],
  },
  {
    text: "Feedback",
    route: "#",
    icon: <FeedbackOutlined />,
    nestedLinks: [
      {
        text: "One",
        route: "#",
      },
      {
        text: "Two",
        route: "#",
      },
      {
        text: "Three",
        route: "#",
      },
    ],
  },
  {
    text: "Communication",
    route: "#",
    icon: <CallOutlined />,
    nestedLinks: [
      {
        text: "One",
        route: "#",
      },
      {
        text: "Two",
        route: "#",
      },
      {
        text: "Three",
        route: "#",
      },
    ],
  },
  {
    text: "Master",
    route: "#",
    icon: <LayersOutlined />,
    nestedLinks: [
      {
        text: "One",
        route: "#",
      },
      {
        text: "Two",
        route: "#",
      },
      {
        text: "Three",
        route: "#",
      },
    ],
  },
  {
    text: "Reports",
    route: "#",
    icon: <AnalyticsOutlined />,
    nestedLinks: [
      {
        text: "One",
        route: "#",
      },
      {
        text: "Two",
        route: "#",
      },
      {
        text: "Three",
        route: "#",
      },
    ],
  },
  {
    text: "Academics",
    route: "#",
    icon: <SchoolOutlined />,
  },
  {
    text: "Maintainence",
    route: "#",
    icon: <EngineeringOutlined />,
  },
  {
    text: "Student Section",
    route: "#",
    icon: <GroupsOutlined />,
  },
  {
    text: "Service Rules",
    route: "#",
    icon: <DescriptionOutlined />,
  },
  {
    text: "IT Ticket",
    route: "#",
    icon: <ComputerOutlined />,
  },
];

import {
  ChartsAxisHighlight,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";

interface AttendanceChartProps {
  xaxis: number[];
  yaxis: number[];
}

const AttendanceChart = ({
  xaxis = [1, 2, 3, 5, 8, 10],
  yaxis = [2, 5.5, 2, 8.5, 1.5, 5],
}: AttendanceChartProps) => {
  return (
    <ResponsiveChartContainer
      height={240}
      xAxis={[
        {
          data: xaxis,
          label: "Day",
        },
      ]}
      series={[
        {
          data: yaxis,
          type: "line",
          label: "Total Hours",
        },
      ]}
    >
      <LinePlot />
      <MarkPlot />
      <ChartsXAxis />
      <ChartsYAxis label="Hours" />
      <ChartsReferenceLine
        y={8}
        // label='Shift Hours'
        lineStyle={{
          stroke: "#db880b",
          strokeWidth: 2,
          strokeDasharray: "8 4",
        }}
        labelAlign="end"
        labelStyle={{
          fill: "#db880b",
          backgroundColor: "white",
        }}
      />
      <ChartsAxisHighlight x="line" />
      <ChartsTooltip />
    </ResponsiveChartContainer>
  );
};

export default AttendanceChart;

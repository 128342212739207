import { Box, Chip, Typography } from '@mui/material'

const Lectures = () => {
  // TODO: create Interface for lectures
  const lectures = [
    {
      subject: 'Data Science and Big Data Analytics',
      class: 'TE-A',
      time: '10:00 AM',
    },
    { subject: 'Machine Learning', class: 'TE-B', time: '11:00 AM' },
    { subject: 'Artificial Intelligence', class: 'TE-C', time: '12:00 PM' },
  ]
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
          padding: '16px',
          paddingTop: '0px',
        }}>
        {lectures.map((lecture, key) => {
          let chipData = lecture.class + ' | ' + lecture.time
          return (
            <Box
              key={key}
              sx={{
                borderBottom: '2px solid',
                borderColor: 'primary.light',
                padding: '8px 0',
              }}>
              <Typography variant='subtitle1'>{lecture.subject}</Typography>
              <Chip label={chipData} variant='filled' />
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default Lectures

import { PieChart } from '@mui/x-charts/PieChart'

interface MyPieChartProps {
  label1: string
  label2: string
  value1: number
  value2: number
}

export default function MyPieChart({
  label1,
  label2,
  value1,
  value2,
}: MyPieChartProps) {
  return (
    <PieChart
      width={270}
      height={200}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
      series={[
        {
          data: [
            { id: 0, value: value1, color: '#00BC78', label: label1 },
            // { id: 1, value: value2, color: '#00BC78', label: label2 },
            { id: 1, value: value2, color: '#DB880B', label: label2 },
          ],
        },
      ]}
    />
  )
}

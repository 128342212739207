import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useStore from "../store/store";

interface Event {
  title: string;
  date: string;
}

export default function Calendar() {
  const { setDialogVariant, setShowDialog, setDialogTitle } = useStore();

  const events: Event[] = [
    { title: "Traditional Day", date: "2024-03-01" },
    { title: "Garba", date: "2024-04-02" },
    { title: "Dandiyaa", date: "2024-04-02" },
  ];

  const renderEventContent = (eventInfo: any) => {
    return (
      <div>
        <p>{eventInfo.event.title}</p>
        {/* <p>{eventInfo.event.startStr}</p> */}
      </div>
    );
  };

  const showDetails = (info: any) => {
    const clickedDate = info.dateStr;
    const clickedEvent = events.find((event) => event.date === clickedDate);
    if (clickedEvent) {
      setDialogVariant("eventDetails");
      setDialogTitle("Event Details");
      setShowDialog(true);
    }
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={events}
      dateClick={showDetails}
      eventContent={renderEventContent}
      // take full space on small pages
      height="auto"
      titleFormat={{ year: "numeric", month: "short" }}
      headerToolbar={{
        start: "title", // will normally be on the left. if RTL, will be on the right
        center: "",
        end: "today prev,next", // will normally be on the right. if RTL, will be on the left
      }}
    />
  );
}

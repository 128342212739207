import { Box, Typography } from '@mui/material'
import HeadDivider from '../components/ui/HeadDivider'
import SocialConnectCard from '../components/SocialConnectCard'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useEffect } from 'react'

const SocialConnect = () => {
  // scroll to top

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box
        component={'div'}
        sx={{
          background: `linear-gradient(25deg, rgba(0,0,0,0.95),rgba(0,0,0,0.25)), url("/social-banner-2.svg")`,
          backgroundSize: 'cover',
          backgroundPosition: { xs: 'right', md: 'center' },
          height: { xs: '90px', md: '100px' },
          padding: '16px',
          borderRadius: '10px',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Typography
          variant='h5'
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '38px' },
            background:
              '-webkit-linear-gradient(-74deg, #FFFFFF 0%, #999999 30%, #999999 60%, #ffffff 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            filter: 'brightness(1.4)',
          }}>
          Social Connect
        </Typography>
      </Box>

      {/* Today */}
      <Box
        component={'section'}
        sx={{
          margin: '32px 0',
        }}>
        <HeadDivider heading='Today' />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
        </Grid2>
      </Box>

      {/* This Month */}
      <Box
        component={'section'}
        sx={{
          margin: '24px 0',
        }}>
        <HeadDivider heading='This Month' />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
          <Grid2 xs={12} md={4} lg={3}>
            <SocialConnectCard />
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}

export default SocialConnect

import useStore from "../store/store";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Typography } from "@mui/material";

// Fields to not display
const ignoreKeys = ["EntryID", "IsEditable", "InTime", "OutTime"];

const ViewDetails = () => {
  const { viewDetails } = useStore();

  if (!viewDetails) {
    throw new Error("Miss Punch data is not available");
  }

  return (
    <Box>
      <Grid2 container spacing={2}>
        {Object.keys(viewDetails[0]).map((key, index) => {
          if (
            (viewDetails[0] as { [key: string]: any })[key] &&
            !ignoreKeys.includes(key)
          )
            return (
              <Grid2 key={index} xs={12} md={6}>
                <Typography variant="h6">{key}</Typography>
                <Typography variant="body1">
                  {(
                    (viewDetails[0] as { [key: string]: any })[key] || "N/A"
                  ).toString()}
                </Typography>
              </Grid2>
            );
        })}
      </Grid2>
    </Box>
  );
};

export default ViewDetails;

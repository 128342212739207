import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SelectInput from "../components/ui/SelectInput";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import HolidayCard from "../components/HolidayCard";
import useStore from "../store/store";
import { useGetHolidayList } from "../services/queries";

const HolidaysList = () => {
  const { setLoading } = useStore();
  const [holidayYear, setHolidayYear] = useState(
    new Date().getFullYear().toString()
  );

  const { data: holidays, isLoading: holidayLoading } = useGetHolidayList(
    parseInt(holidayYear)
  );

  const handleHolidayYearChange = (event: SelectChangeEvent) => {
    setHolidayYear(event.target.value);
  };

  useEffect(() => {
    setLoading(holidayLoading);
  }, [holidayLoading]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}
      >
        Holidays
      </Typography>
      <Box
        sx={{
          maxWidth: "content",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Typography>Select Year</Typography>
        <SelectInput
          value={holidayYear}
          options={["2019", "2020", "2021", "2022", "2023", "2024"]}
          onChange={handleHolidayYearChange}
        />
      </Box>

      <Box sx={{ height: 24 }} />

      <Grid2 container spacing={2}>
        {holidays?.map((holiday, key) => (
          <Grid2 key={key} xs={12} md={6}>
            <HolidayCard
              month={holiday.monthName}
              holidays={holiday.holidays}
            />
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
};

export default HolidaysList;

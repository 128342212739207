import { Box, Tooltip, Typography } from '@mui/material'

interface HolidayChipProps {
  day: number
  label: string
  weekday: string
}

const HolidayChip = ({ day, label, weekday }: HolidayChipProps) => {
  return (
    <Tooltip title={label}>
      <Box
        sx={{
          display: 'flex',
          borderRadius: '12px',
          py: '4px',
          px: '12px',
          backgroundColor: '#F1F4FA',
          gap: 2,
          alignItems: 'center',
          height: '100%',
          width: '100%',
          cursor: 'default',
        }}>
        <Typography sx={{ color: '#E06674', fontWeight: 600, fontSize: 42 }}>
          {day.toString().padStart(2, '0')}
        </Typography>
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          <Typography
            sx={{
              fontSize: 18,
            }}
            noWrap={true}>
            {label}
          </Typography>
          <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
            {weekday}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default HolidayChip

import {
  Page,
  Document,
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'

import { Table, TR, TH, TD } from '@ag-media/react-pdf-table'

const styles = StyleSheet.create({
  boldtext: {
    fontFamily: 'Helvetica-Bold',
  },
  detailtext: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: 1.5,
    marginBottom: '4pt',
  },
  tableCellLeft: {
    padding: '6px',
    lineHeight: 1,
  },
  tableCellRight: {
    padding: '6px',
    justifyContent: 'flex-end',
    lineHeight: 1,
  },
  tableHeadLeft: {
    padding: '6px',
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1,
  },
  tableHeadRight: {
    padding: '6px',
    fontFamily: 'Helvetica-Bold',
    justifyContent: 'flex-end',
    lineHeight: 1,
  },
})

const FeeReceipt = () => (
  <Document>
    <Page
      size='A4'
      style={{
        padding: '24pt',
        fontFamily: 'Helvetica',
        // fontFamily: "Roboto",
        fontSize: '10pt',
        lineHeight: 1.3,
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: '24pt',
          alignItems: 'center',
          paddingVertical: '12pt',
        }}>
        <View>
          <Image
            // src="https://picsum.photos/200/100"
            src='https://dit-erp-react.vercel.app/dpu-logo.png'
            style={{ height: '60pt', width: '120pt', objectFit: 'contain' }}
          />
        </View>
        <View
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: '6pt',
            textAlign: 'center',
            fontSize: '9pt',
            alignItems: 'center',
          }}>
          <Text style={styles.boldtext}>Dr. D. Y. Patil Unitech Society</Text>
          <Text style={{ fontSize: '16pt' }}>
            Dr. D. Y. Patil Institute of Technology
          </Text>
          <Text style={styles.boldtext}>
            Sant Tukaram Nagar, Pimpri, Pune 411018
          </Text>
        </View>
      </View>

      <View style={{ border: '2px solid black' }}>
        <Text
          style={[
            styles.boldtext,
            {
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: '18pt',
              padding: '4pt',
              lineHeight: 1,
            },
          ]}>
          Receipt
        </Text>
        <View style={{ borderTop: '2px black solid', padding: '12pt' }}>
          <View style={{ display: 'flex', flexDirection: 'row', gap: '9pt' }}>
            <View style={{ width: '64%' }}>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Date: </Text>26-07-2023
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Name: </Text>Jawale Yash Sudhakar
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Address: </Text>Sr. No. 81/1,
                Maruti Nagar, Lohegaon Wagholi Road, Lohegaon, Pune
              </Text>
            </View>
            <View style={{ width: '36%' }}>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Receipt No.: </Text>23-24 / 2933
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>ERPStudentID: </Text>21512352
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Class: </Text>TE-Comp-A
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', gap: '9pt' }}>
            <View style={{ width: '32%' }}>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Contact No.: </Text>9307918036
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Admission Category: </Text>EBC
              </Text>
            </View>
            <View style={{ width: '32%' }}>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Course Name: </Text>BE
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Admission Year: </Text>2021
              </Text>
            </View>
            <View style={{ width: '36%' }}>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Class Year: </Text>3
              </Text>
              <Text style={styles.detailtext}>
                <Text style={styles.boldtext}>Branch Name: </Text>Computer
                Engineering
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            borderTop: '2px black solid',
            padding: '12pt',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}>
          <View style={{ width: '50%', fontSize: '10pt' }}>
            <Table weightings={[0.15, 0.6, 0.25]}>
              <TH>
                <TD style={styles.tableHeadLeft}>Sr. No.</TD>
                <TD style={styles.tableHeadLeft}>Ledger Name</TD>
                <TD style={styles.tableHeadRight}>Amount</TD>
              </TH>
              <TR>
                <TD style={styles.tableCellLeft}>1</TD>
                <TD style={styles.tableCellLeft}>TutionFees</TD>
                <TD style={styles.tableCellRight}>51,771</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}>2</TD>
                <TD style={styles.tableCellLeft}>DevelopmentFees</TD>
                <TD style={styles.tableCellRight}>13,459</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}>3</TD>
                <TD style={styles.tableCellLeft}>ScholarshipFees</TD>
                <TD style={styles.tableCellRight}>51,770</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}></TD>
                <TD style={styles.tableHeadLeft}>Total Fees</TD>
                <TD style={styles.tableHeadRight}>1,17,000</TD>
              </TR>
            </Table>
          </View>
          <View style={{ width: '50%', fontSize: '10pt' }}>
            <Table weightings={[0.5, 0.5]}>
              <TR>
                <TD style={styles.tableCellLeft}>Allocated Fees</TD>
                <TD style={styles.tableCellRight}>65,230</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}>Previously Received</TD>
                <TD style={styles.tableCellRight}>0</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}>Current Receipt</TD>
                <TD style={styles.tableCellRight}>65,230</TD>
              </TR>
              <TR>
                <TD style={styles.tableCellLeft}>Balance</TD>
                <TD style={styles.tableCellRight}>0</TD>
              </TR>
            </Table>
          </View>
        </View>

        <View style={{ borderTop: '2px black solid', padding: '12pt' }}>
          <Text>
            Received online through Payment Gateway - Transaction ID -{' '}
            <Text style={styles.boldtext}>E230726LO801VY</Text>
          </Text>
          <Text>
            <Text style={styles.boldtext}>Account Realization Date:- </Text>
          </Text>
          <Text>
            <Text style={styles.boldtext}>Account Holder Name:- </Text>
          </Text>
        </View>

        <View
          style={{
            borderTop: '2px black solid',
            padding: '12pt',
            display: 'flex',
            flexDirection: 'row',
            gap: '12pt',
          }}>
          <View>
            <Text>Amount in Words:</Text>
            <Text style={styles.boldtext}>
              Rupees Sixty Five Thousand Two Hundred and Thirty only.
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 'auto',
            }}>
            <Text>Amount:-</Text>
            <View
              style={{
                border: '2px solid black',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: 120,
                padding: 1,
                marginLeft: '6pt',
              }}>
              <Text
                style={[styles.boldtext, { fontSize: '16pt', lineHeight: 1 }]}>
                {/* &#x20B9;  */}
                Rs. 65,230/-
              </Text>
            </View>
          </View>
        </View>

        <View style={{ borderTop: '2px black solid', padding: '12pt' }}>
          <Text style={[styles.boldtext, { textDecoration: 'underline' }]}>
            Conditions:
          </Text>
          <Text>1. The Payment of fees does not give right to admission.</Text>
          <Text>
            2. Any legal dispute between the college and student or his/her
            guardian/parent, arising out of the relations established by
            admission, will be dealt with in the Court of C. J. J. D, Pune,
            Maharastra.
          </Text>
          <Text>
            3. This orignal receipt must be produced while claiming the refund
            in case of cancellation of admission or in case of double payment.
          </Text>
        </View>

        <View style={{ borderTop: '2px black solid', padding: '12pt' }}>
          <Text style={{ fontSize: '9pt' }}>
            I have read the conditions rules & regulations of the
            college/Vidyapeeth, I agree to abide by them
          </Text>
          <View style={{ paddingTop: 26 }}>
            <Text style={{ textAlign: 'right' }}>Signature of Candidate</Text>
          </View>
        </View>

        <View style={{ borderTop: '2px black solid', padding: '6pt' }}>
          <Text style={{ textAlign: 'center', fontSize: '9pt' }}>
            This a computer generated Receipt and does not require a signature
          </Text>
        </View>
      </View>
    </Page>
  </Document>
)

export default FeeReceipt

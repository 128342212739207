import { ArrowOutwardOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

const NoticesAndCirculars = () => {
  // todo - create interface for notice
  const notices = [
    //    make 2 notices - one for term end and another for vacations
    {
      title: 'Term End Notice',
      description:
        'The term end exams are scheduled to start from 20th of December. Please make sure to pay your fees and collect your hall tickets from the office.',
    },
    {
      title: 'Vacation Notice',
      description:
        'The college will remain closed from 25th of December to 5th of January. The college will reopen on 6th of January. Please make sure to vacate the hostel rooms before leaving.',
    },
  ]
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '16px',
          padding: '16px',
          paddingTop: '0px',
        }}>
        {notices.map((notice, key) => {
          return (
            <Box
              key={key}
              sx={{
                backgroundColor: 'background.default',
                padding: '10px',
                borderRadius: '10px',
                borderColor: 'primary.main',
                position: 'relative',
              }}>
              <Typography
                variant='subtitle1'
                color={'primary.main'}
                fontWeight={500}
                fontSize={'18px'}>
                {notice.title}
              </Typography>
              <Typography
                lineHeight={1.25}
                color={'primary.main'}
                fontWeight={400}>
                {notice.description}
              </Typography>
              <ArrowOutwardOutlined
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
              />
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default NoticesAndCirculars

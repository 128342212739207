import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  DeleteOutlineOutlined,
  RemoveRedEyeOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import HeadDivider from "../components/ui/HeadDivider";
import theme from "../config/AppTheme";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  categoriesOptions,
  departmentsOptions,
  religionsOptions,
} from "../utils/utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface FormValues {
  salutation: string;
  staffName: string;
  gender: string;
  maritalStatus: string;
  birthDate: Date | null;
  isHandicapped: string;
  bloodGroup: string;
  highestQualification: string;
  personalMobile: string;
  alternateMobile: string;
  category: string;
  religion: string;
  caste: string;
  subCaste: string;
  personalEmail: string;
  staffID: string;
  currentStatus: string;
  dateOfJoining: Date | null;
  shift: string;
  role: string;
  designation: string;
  department: string;
  natureOfAppointment: string;
  employmentType: string;
  workCategory: string;
  workGroup: string;
  payrollIn: string;
  officeContact: string;
  officialEmail: string;
  currentAddress: string;
  currentCountry: string;
  currentState: string;
  currentCity: string;
  currentPinCode: string;
  permanentAddress: string;
  permanentCountry: string;
  permanentState: string;
  permanentCity: string;
  permanentPinCode: string;
  panNo: string;
  aadharNo: string;
  pfNo: string;
  uanNo: string;
  accountNo: string;
  bankName: string;
  nomineeName: string;
  relation: string;
}

interface CountryType {
  countryId: number;
  sortName: string;
  countryName: string;
  phoneCode: number;
  masterStates: any[];
}

interface ReligionType {
  religionId: number;
  religion: string;
}
interface DepartmentType {
  deptId: number;
  departmentName: string;
  departmentType: string;
  parentId: any;
  shortName: any;
  isPresent: any;
}

interface CategoryType {
  categoryId: number;
  category: string;
  isPresent: boolean;
  masterStaffs: any[];
}

const StaffEnrollment = () => {
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [same, setSame] = useState(false);
  const [countries, setCountries] = useState<CountryType[]>();

  const [religions, setReligions] = useState<ReligionType[]>(religionsOptions);
  const [departments, setDepartments] =
    useState<DepartmentType[]>(departmentsOptions);
  const [categories, setCategories] =
    useState<CategoryType[]>(categoriesOptions);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      salutation: "",
      staffName: "",
      gender: "",
      maritalStatus: "",
      birthDate: null,
      isHandicapped: "",
      bloodGroup: "",
      highestQualification: "",
      personalMobile: "",
      alternateMobile: "",
      category: "",
      religion: "",
      caste: "",
      subCaste: "",
      personalEmail: "",
      staffID: "",
      currentStatus: "",
      dateOfJoining: null,
      shift: "",
      role: "",
      designation: "",
      department: "",
      natureOfAppointment: "",
      employmentType: "",
      workCategory: "",
      workGroup: "",
      payrollIn: "",
      officeContact: "",
      officialEmail: "",
      currentAddress: "",
      currentCountry: "",
      currentState: "",
      currentCity: "",
      currentPinCode: "",
      permanentAddress: "",
      permanentCountry: "",
      permanentState: "",
      permanentCity: "",
      permanentPinCode: "",
      panNo: "",
      aadharNo: "",
      pfNo: "",
      uanNo: "",
      accountNo: "",
      bankName: "",
      nomineeName: "",
      relation: "",
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const handleCheckboxChange = (e: { target: { checked: any } }) => {
    const checked = e.target.checked;
    setSame(checked ? true : false);

    if (checked) {
      const values = getValues();
      setValue("permanentAddress", values.currentAddress);
      setValue("permanentCountry", values.currentCountry);
      setValue("permanentState", values.currentState);
      setValue("permanentCity", values.currentCity);
      setValue("permanentPinCode", values.currentPinCode);
    } else {
      // Optionally clear or reset the permanent address fields
      setValue("permanentAddress", "");
      setValue("permanentCountry", "");
      setValue("permanentState", "");
      setValue("permanentCity", "");
      setValue("permanentPinCode", "");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImage(reader.result as string);
      }
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        let categoriesData: CategoryType[] = (
          await axios.get("https://1600.sdctest.in/Master/mycategory")
        ).data;
        setCategories(categoriesData);

        let religionsData: ReligionType[] = (
          await axios.get("https://1600.sdctest.in/Master/Religions")
        ).data;
        setReligions(religionsData);

        let departmentsData: DepartmentType[] = (
          await axios.get("https://1600.sdctest.in/Master/Departments")
        ).data;
        setDepartments(departmentsData);

        let countriesData: CountryType[] = (
          await axios.get("https://1600.sdctest.in/Master/Countries")
        ).data;
        setCountries(countriesData);
      } catch (err) {
        console.log(err);
      }
    };
    getOptions();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2} mt={1}>
        {/* Photo Upload Section */}
        <Grid2 xs={12} md={4}>
          <HeadDivider heading="Photo Upload" />
          <Box
            sx={{
              gap: "40px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <Stack direction={"row"}>
              <Stack>
                {/* Assume primaryData?.Photobyte is available */}
                <img
                  src={image?.toString()}
                  width={"120px"}
                  height={"120px"}
                  style={{
                    border: "2px solid #445569",
                    padding: "3px",
                  }}
                />
                <Grid2 marginTop={2} xs={6}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    color="success"
                    tabIndex={-1}
                    startIcon={<UploadFileOutlined />}
                  >
                    Upload
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid2>
              </Stack>
              <Grid2
                marginLeft={2}
                alignSelf={"center"}
                sx={{
                  padding: "8px",
                  borderRadius: "12px",
                  border: "1px solid",
                  borderColor: "#d69d00",
                  alignContent: "center",
                  color: "#d69d00",
                }}
              >
                <Typography variant="subtitle2">
                  1. Type: PNG/JPEG/JPG
                </Typography>
                <Typography variant="subtitle2">
                  2. Dimensions : 320 X 240 px
                </Typography>
                <Typography variant="subtitle2">
                  3. Size : &lt; 600 kb
                </Typography>
              </Grid2>
            </Stack>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={4}></Grid2>
        <Grid2 xs={12} md={4}>
          <HeadDivider heading="Search Staff" />
          <Box
            sx={{
              gap: "40px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <Stack direction={"row"}>
              <Grid2 xs={12} md={6}>
                <TextField label="ID" value=""></TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Button variant="outlined" color="info">
                  Search
                </Button>
              </Grid2>
            </Stack>
            <Stack direction={"row"}>
              <Grid2 xs={12} md={6}>
                <TextField label="Name" value=""></TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Button variant="outlined" color="info">
                  Search
                </Button>
              </Grid2>
            </Stack>
          </Box>
        </Grid2>
        {/* Personal Info Section */}
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Personal Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={3}>
                <TextField
                  label="Salutation"
                  select
                  value={watch("salutation")}
                  {...register("salutation")}
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={9}>
                <TextField
                  label="Staff Name"
                  value={watch("staffName")}
                  {...register("staffName", {
                    required: true,
                  })}
                  error={!!errors.staffName}
                  // helperText={errors.staffName?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={5} md={4}>
                <TextField
                  label="Gender"
                  select
                  value={watch("gender")}
                  {...register("gender")}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid2>

              <Grid2 xs={7} md={4}>
                <TextField
                  label="Marital Status"
                  select
                  value={watch("maritalStatus")}
                  {...register("maritalStatus")}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={4}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Birth Date"
                  {...register("birthDate")}
                  onChange={() => {}}
                />
              </Grid2>

              <Grid2 xs={12} md={4}>
                <TextField
                  label="Is Handicapped"
                  select
                  value={watch("isHandicapped")}
                  {...register("isHandicapped")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Blood Group"
                  select
                  value={watch("bloodGroup")}
                  {...register("bloodGroup")}
                >
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Highest Qualification"
                  select
                  value={watch("highestQualification")}
                  {...register("highestQualification")}
                >
                  <MenuItem value="MSc">MSc</MenuItem>
                  <MenuItem value="BSc">BSc</MenuItem>
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Personal Mobile"
                  value={watch("personalMobile")}
                  {...register("personalMobile")}
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Alternate Mobile"
                  value={watch("alternateMobile")}
                  {...register("alternateMobile")}
                />
              </Grid2>

              <Grid2 xs={6} md={6}>
                <TextField
                  label="Category"
                  select
                  value={watch("category")}
                  {...register("category")}
                >
                  {categories?.map((category: CategoryType) => (
                    <MenuItem
                      value={category.category}
                      key={category.categoryId}
                    >
                      {category.category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>

              <Grid2 xs={6} md={6}>
                <TextField
                  label="Religion"
                  select
                  value={watch("religion")}
                  {...register("religion")}
                >
                  {religions?.map((religion: ReligionType) => (
                    <MenuItem
                      value={religion.religionId}
                      key={religion.religionId}
                    >
                      {religion.religion}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Caste"
                  value={watch("caste")}
                  {...register("caste")}
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Sub Caste"
                  value={watch("subCaste")}
                  {...register("subCaste")}
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <TextField
                  label="Personal Email"
                  value={watch("personalEmail")}
                  {...register("personalEmail", {
                    required: true,
                  })}
                  error={!!errors.personalEmail}
                  // helperText={errors.personalEmail?.message}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Work Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Staff ID"
                  value={watch("staffID")}
                  {...register("staffID", {
                    required: true,
                  })}
                  error={!!errors.staffID}
                  // helperText={errors.staffID?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Current Status"
                  value={watch("currentStatus")}
                  {...register("currentStatus")}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Date Of Joining"
                  {...register("dateOfJoining")}
                  onChange={() => {}}
                />
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Shift"
                  select
                  value={watch("shift")}
                  {...register("shift", {
                    required: true,
                  })}
                  error={!!errors.shift}
                >
                  <MenuItem value="Morning">Morning</MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Role"
                  select
                  value={watch("role")}
                  {...register("role", {
                    required: true,
                  })}
                  error={!!errors.role}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Teacher">Teacher</MenuItem>
                  <MenuItem value="Clerk">Clerk</MenuItem>
                  <MenuItem value="Accountant">Accountant</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6} md={4}>
                <TextField
                  label="Designation"
                  select
                  value={watch("designation")}
                  {...register("designation", {
                    required: true,
                  })}
                  error={!!errors.designation}
                >
                  <MenuItem value="Principal">Principal</MenuItem>
                  <MenuItem value="HOD">HOD</MenuItem>
                  <MenuItem value="Coordinator">Coordinator</MenuItem>
                  <MenuItem value="Lecturer">Lecturer</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6} md={6}>
                <TextField
                  label="Department"
                  select
                  value={watch("department")}
                  {...register("department", {
                    required: true,
                  })}
                  error={!!errors.department}
                >
                  {departments?.map((department: DepartmentType) => (
                    <MenuItem value={department.deptId} key={department.deptId}>
                      {department.departmentName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Nature Of Appointment"
                  select
                  value={watch("natureOfAppointment")}
                  {...register("natureOfAppointment")}
                >
                  <MenuItem value="Permanent">Permanent</MenuItem>
                  <MenuItem value="Contract">Contract</MenuItem>
                  <MenuItem value="Temporary">Temporary</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Employment Type"
                  select
                  value={watch("employmentType")}
                  {...register("employmentType", {
                    required: true,
                  })}
                  error={!!errors.employmentType}
                >
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                  <MenuItem value="Intern">Intern</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6} md={6}>
                <TextField
                  label="Work Category"
                  select
                  value={watch("workCategory")}
                  {...register("workCategory", {
                    required: true,
                  })}
                  error={!!errors.workCategory}
                >
                  <MenuItem value="Teaching">Teaching</MenuItem>
                  <MenuItem value="Non-teaching">Non-teaching</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={6} md={6}>
                <TextField
                  label="Work Group"
                  select
                  value={watch("workGroup")}
                  {...register("workGroup")}
                >
                  <MenuItem value="Group A">Group A</MenuItem>
                  <MenuItem value="Group B">Group B</MenuItem>
                  <MenuItem value="Group C">Group C</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Payroll In"
                  select
                  value={watch("payrollIn")}
                  {...register("payrollIn")}
                >
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Quarterly">Quarterly</MenuItem>
                  <MenuItem value="Annually">Annually</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Office Contact"
                  value={watch("officeContact")}
                  {...register("officeContact")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Official Email"
                  value={watch("officialEmail")}
                  {...register("officialEmail")}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Current Address" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12}>
                <TextField
                  multiline
                  minRows={3}
                  label="Address"
                  value={watch("currentAddress")}
                  {...register("currentAddress", {
                    required: true,
                  })}
                  error={!!errors.currentAddress}
                  // helperText={errors.currentAddress?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Country"
                  value={watch("currentCountry")}
                  {...register("currentCountry")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="State"
                  value={watch("currentState")}
                  {...register("currentState")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="City"
                  value={watch("currentCity")}
                  {...register("currentCity")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Pin Code"
                  value={watch("currentPinCode")}
                  {...register("currentPinCode")}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6} mt={{ xs: 0, md: -5 }}>
          <FormControlLabel
            label="Same As Current Address"
            control={<Checkbox onChange={handleCheckboxChange} />}
            checked={same}
          />
          <HeadDivider heading="Permanent Address" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12}>
                <TextField
                  multiline
                  minRows={3}
                  label="Address"
                  value={watch("permanentAddress")}
                  {...register("permanentAddress", {
                    required: true,
                  })}
                  error={!!errors.permanentAddress && !same}
                  // helperText={errors.permanentAddress?.message?.toString()}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Country"
                  select
                  value={watch("permanentCountry")}
                  {...register("permanentCountry")}
                >
                  {countries?.map((country: any) => (
                    <MenuItem value={country.countryId} key={country.countryId}>
                      {country.countryName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="State"
                  value={watch("permanentState")}
                  {...register("permanentState")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="City"
                  value={watch("permanentCity")}
                  {...register("permanentCity")}
                />
              </Grid2>
              <Grid2 xs={6}>
                <TextField
                  label="Pin Code"
                  value={watch("permanentPinCode")}
                  {...register("permanentPinCode")}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Account Info" />
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: 3,
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Grid2 container spacing={2} sx={{ padding: 0 }}>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="PAN No."
                  value={watch("panNo")}
                  {...register("panNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Aadhar No."
                  value={watch("aadharNo")}
                  {...register("aadharNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="PF No."
                  value={watch("pfNo")}
                  {...register("pfNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="UAN No."
                  value={watch("uanNo")}
                  {...register("uanNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Account No."
                  value={watch("accountNo")}
                  {...register("accountNo")}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <TextField
                  label="Bank Name"
                  value={watch("bankName")}
                  {...register("bankName")}
                />
              </Grid2>
              <Grid2 xs={12} md={8}>
                <TextField
                  label="Nominee Name"
                  value={watch("nomineeName")}
                  {...register("nomineeName")}
                />
              </Grid2>
              <Grid2 xs={12} md={4}>
                <TextField
                  label="Relation"
                  value={watch("relation")}
                  {...register("relation")}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}></Grid2>
        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Upload Document" />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              backgroundColor: "background.paper",
              padding: "24px",
              borderRadius: "12px",
              border: "1px solid",
              borderColor: "primary.main",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                gap: "24px",
                alignItems: "center",
              },
            }}
          >
            <Grid2 xs={12} md={6}>
              <TextField label="Document Type" select value="">
                <MenuItem value="PAN">PAN</MenuItem>
                <MenuItem value="Aadhar">Aadhar</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                color="success"
                tabIndex={-1}
                startIcon={<UploadFileOutlined />}
              >
                Upload
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <HeadDivider heading="Documents Uploaded" />
          <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
            <Table
              sx={{
                width: {
                  xs: "100%",
                },
                maxWidth: {
                  sm: 620,
                },
                minWidth: {
                  sm: 620,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1.</TableCell>
                  <TableCell>Aadhar</TableCell>
                  <TableCell>aadhar.pdf</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2.</TableCell>
                  <TableCell>PAN</TableCell>
                  <TableCell>pan.pdf</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <IconButton color="primary" size="small" onClick={() => {}}>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      </Grid2>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        gap={4}
        marginTop={5}
      >
        <Button variant="outlined" color="info">
          Cancel
        </Button>
        <Button variant="outlined" color="warning">
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default StaffEnrollment;

import { MailOutline, Phone } from "@mui/icons-material";
import { Box, Chip, Typography, useTheme } from "@mui/material";

interface Props {
  name: string | undefined;
  designation: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  photoByte: string | undefined;
}
const PrimaryInfo = ({ name, designation, phone, email, photoByte }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "40px",
        backgroundColor: "background.paper",
        padding: "24px",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "primary.main",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          gap: "24px",
          alignItems: "center",
        },
      }}
    >
      {photoByte ? (
        <img
          src={`data:image/png;base64,${photoByte}`}
          alt="profile"
          width={"200px"}
          height={"200px"}
          style={{
            borderRadius: "100%",
            border: "2px solid #445569",
            padding: "3px",
          }}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "secondary.main",
            fontWeight: "bold",
            textTransform: "none",
            fontSize: "36px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {name}
        </Typography>

        <Chip
          label={designation}
          color="primary"
          variant="filled"
          sx={{
            borderRadius: "8px",
            textTransform: "uppercase",
            marginTop: "8px",
            alignSelf: "flex-start",
            [theme.breakpoints.down("md")]: { alignSelf: "center" },
          }}
        />

        <Box
          sx={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <Phone color="primary" />
          <Box component={"p"}>{phone}</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <MailOutline color="primary" />
          <Box component={"p"}>{email}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrimaryInfo;

import { StateCreator } from "zustand";

// Define the MiscState type
type MiscState = {
  isLoading: boolean;
  compOffId: string;
  showMobileSearchBar: boolean;
  expandMobileSearchBar: boolean;
};

// Define the MiscActions type
type MiscActions = {
  setLoading: (isLoading: boolean) => void;
  setCompOffId: (compOffId: string) => void;
  setShowMobileSearchBar: (value: boolean) => void;
  setExpandMobileSearchBar: (value: boolean) => void;
};

// Combine MiscState and MiscActions into a single MiscSlice type
export type MiscSlice = MiscState & MiscActions;

// Create the misc slice using the StateCreator
export const createMiscSlice: StateCreator<MiscSlice> = (set) => ({
  // Initialize the miscellaneous states
  isLoading: false,
  showMobileSearchBar: false,
  expandMobileSearchBar: false,
  compOffId: "",

  // Define actions to update each state
  setLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setCompOffId: (compOffId: string) => set(() => ({ compOffId })),
  setShowMobileSearchBar: (showMobileSearchBar: boolean) =>
    set(() => ({ showMobileSearchBar })),
  setExpandMobileSearchBar: (expandMobileSearchBar: boolean) =>
    set(() => ({ expandMobileSearchBar })),
});

import { FormEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { DialogAction, DialogBody } from "../config/Dialog";
import useStore from "../store/store";

export default function FormDialog() {
  const { showDialog, setShowDialog, dialogTitle, dialogVariant } = useStore();

  const handleClose = () => {
    setShowDialog(false);
  };
  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
              const email = formJson.email;
            console.log(email)
            handleClose();
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "primary.main",
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent>{DialogBody[dialogVariant]}</DialogContent>
        <DialogActions>{DialogAction[dialogVariant]}</DialogActions>
      </Dialog>
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import HeadDivider from "../components/ui/HeadDivider";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  ArrowOutwardOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  RemoveRedEyeOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useStore from "../store/store";
import { useCompOffRegister, useDisplayCompOff } from "../services/queries";
import {
  useSaveCompOffMutation,
  useUpdateCompOffMutation,
} from "../services/mutations";
import { CompOffFormData, SingleCompOffDataType } from "../types/compOff";

dayjs.extend(utc);
dayjs.extend(timezone);

const CompOff: React.FC = () => {
  const [editingForm, setEditingForm] = useState(false);
  const [compOffEntryId, setCompOffEntryId] = useState("");
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const [fetchKey, setFetchKey] = useState<number>(0);
  const [entryDate, setEntryDate] = useState<dayjs.Dayjs>(dayjs());
  const [referenceNo, setReferenceNo] = useState<string>("");
  const [workPlace, setWorkPlace] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    setLoading,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
    setViewDetails,
    setCompOffId,
  } = useStore();

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { },
    reset,
  } = useForm<CompOffFormData>({
    defaultValues: {
      entryID: compOffEntryId,
      compOffDate: "",
      description: "",
      workPlace: "",
    },
  });

  const {
    data: registerData,
    isLoading: registerLoading,
    refetch: refetchCompOffRegister,
  } = useCompOffRegister();

  const {
    data: singleCompOffData,
    isLoading: displayLoading,
    refetch: refetchSingleCompOff,
  } = useDisplayCompOff(compOffEntryId);

  const { isPending: saveLoading, mutateAsync: saveAsync } =
    useSaveCompOffMutation();
  const { isPending: updateLoading, mutateAsync: updateAsync } =
    useUpdateCompOffMutation();

  const onSaveHandler = async (data: CompOffFormData) => {
    await saveAsync(data);
    refetchCompOffRegister();
    reset();
  };

  const onUpdateHandler = async (data: CompOffFormData) => {
    data.entryID = compOffEntryId;
    await updateAsync(data);
    refetchCompOffRegister();
    reset();
    setEditingForm(false);
    setReferenceNo("");
    setEntryDate(dayjs());
  };

  useEffect(() => {
    setLoading(
      saveLoading || registerLoading || displayLoading || updateLoading
    );
  }, [saveLoading, updateLoading, registerLoading, displayLoading, setLoading]);

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = useMemo(() => {
    if (!registerData) return 0;
    return Math.max(
      0,
      rowsPerPage -
        Math.min(rowsPerPage, registerData.length - page * rowsPerPage)
    );
  }, [registerData, rowsPerPage, page]);

  const handleEditCompOff = (id: string) => {
    setCompOffEntryId(id);
    setFetchKey((prev) => prev + 1);
    setEditingForm(true);
    setTextAreaFocused(true);
    window.scrollTo(0, 0);
  };

  const handleDisplayCompOff = (id: string) => {
    setEditingForm(false);
    setCompOffEntryId(id);
    setFetchKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (singleCompOffData && Object.keys(singleCompOffData).length > 0) {
      if (editingForm) {
        const data: SingleCompOffDataType = singleCompOffData[0];
        const { EntryDate, WorkDescription, WorkPlace, CompOffNo } = data;
        setReferenceNo(CompOffNo.toString());
        setEntryDate(dayjs(EntryDate));
        setWorkPlace(WorkPlace);
        setValue("workPlace", WorkPlace);
        setValue("description", WorkDescription);
        window.scrollTo(0, 0);
      } else {
        setViewDetails(singleCompOffData);
        setDialogTitle("Comp Off Details");
        setDialogVariant("viewDetails");
        setShowDialog(true);
      }
    }
  }, [
    singleCompOffData,
    editingForm,
    setValue,
    setDialogTitle,
    setDialogVariant,
    setShowDialog,
  ]);

  useEffect(() => {
    if (fetchKey > 0) {
      refetchSingleCompOff();
    }
  }, [fetchKey, refetchSingleCompOff]);

  return (
    <div>
      <Typography
        variant="h1"
        sx={{ fontSize: 30, fontWeight: 400, paddingY: 2 }}
      >
        Comp Off Credit
      </Typography>
      <HeadDivider heading="Form" />
      <Grid2 container spacing={2}>
        {/* Form section */}
        <Grid2 xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderRadius: 3,
              padding: "20px 16px",
              borderColor: (theme) => theme.palette.primary.main,
              height: "100%",
            }}
          >
            {editingForm && (
              <Alert
                variant="standard"
                severity="info"
                sx={{ marginBottom: 2 }}
              >
                <Typography variant="body2">
                  {`Editing EntryId: ${compOffEntryId}`}
                </Typography>
              </Alert>
            )}

            <form
              onSubmit={handleSubmit(
                editingForm ? onUpdateHandler : onSaveHandler
              )}
            >
              {/* Form fields */}
              <Grid2 container spacing={2} sx={{ padding: 0 }}>
                <Grid2 xs={12} md={6}>
                  <TextField
                    label="Reference No."
                    value={referenceNo}
                    disabled
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  {/* disabled and today's date */}
                  <DatePicker
                    value={entryDate}
                    readOnly
                    label="Entry Date"
                    format="DD/MM/YYYY"
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Controller
                    control={control}
                    name="compOffDate"
                    render={({ field }) => (
                      <DatePicker
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                        timezone="Asia/Kolkata"
                        {...field}
                        value={field.value || null}
                        format="DD/MM/YYYY"
                        label="Comp Off Date"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <FormLabel>Work Place</FormLabel>
                  <RadioGroup row value={workPlace}>
                    <FormControlLabel
                      value="Within Campus"
                      control={
                        <Radio
                          {...register("workPlace")}
                          onChange={() => {
                            setWorkPlace("Within Campus");
                          }}
                          required
                        />
                      }
                      label="Within Campus"
                    />
                    <FormControlLabel
                      value="Outside Campus"
                      control={
                        <Radio
                          {...register("workPlace")}
                          onChange={() => {
                            setWorkPlace("Outside Campus");
                          }}
                          required
                        />
                      }
                      label="Outside Campus"
                    />
                  </RadioGroup>
                </Grid2>
                <Grid2 xs={12}>
                  <TextField
                    focused={textAreaFocused}
                    required
                    multiline
                    minRows={3}
                    label="Work Description"
                    {...register("description")}
                    onBlur={() => {
                      getValues("description") === "" &&
                        setTextAreaFocused(false);
                    }}
                  />
                </Grid2>
              </Grid2>

              {editingForm ? (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      setValue("description", "");
                      setEditingForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{ margin: 0, marginTop: "16px" }}
                    onClick={() => {
                      reset();
                      setEntryDate(dayjs());
                      setWorkPlace("");
                      setEditingForm(false);
                      setReferenceNo("");
                      reset({
                        entryID: "",
                        description: "",
                        compOffDate: undefined,
                        workPlace: "",
                      });
                    }}
                  >
                    New
                  </Button>
                  <Button type="submit" sx={{ margin: "16px 0 0 6px" }}>
                    Save
                  </Button>
                </>
              )}
            </form>
          </Box>
        </Grid2>

        {/* Warning section */}
        <Grid2 xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: 3,
              padding: 3,
              color: "white",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <WarningOutlined sx={{ color: "#F2CB85", fontSize: 36 }} />
            <Typography sx={{ fontSize: 18, paddingBottom: 1 }}>
              Before applying CompOff, Please check{" "}
              <strong>IN TIME and OUT TIME</strong> on My Attendance page.
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              If <strong>IN TIME and OUT TIME</strong> are missing on My
              Attendance page, Contact to <strong>HR Department.</strong>
            </Typography>
            <Link
              to="/dashboard/my-attendance"
              style={{
                color: "black",
                padding: "6px 14px",
                borderRadius: 8,
                textDecoration: "none",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                marginTop: 12,
                fontSize: 14,
              }}
            >
              Go to Attendance Page <ArrowOutwardOutlined />
            </Link>
          </Box>
        </Grid2>
      </Grid2>

      {/* Approval Flow */}
      <Typography
        sx={{
          paddingY: 1,
          color: (theme) => theme.palette.primary.main,
          fontWeight: "bold",
        }}
      >
        Approval Flow:
      </Typography>

      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Authority</TableCell>
              <TableCell>Approval Date</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1.</TableCell>
              <TableCell>HOD - Mr. Arun D. Sonar</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.</TableCell>
              <TableCell>HOD - Dr. L. K. Wadhwa</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* HISTORY */}

      <Box sx={{ height: 24 }} />
      <HeadDivider heading="History" />
      <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Entry Id</TableCell>
              <TableCell>CompOffDate</TableCell>
              <TableCell>LogIn/Logout</TableCell>
              <TableCell>Work Place</TableCell>
              <TableCell>Work Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Leave Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registerData &&
              registerData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow key={row.EntryID}>
                    <TableCell>{row.EntryID}.</TableCell>
                    <TableCell>{row.CompOffDate}</TableCell>
                    <TableCell>
                      {row.LogIn}/{row.LogOut}
                    </TableCell>
                    <TableCell>{row.WorkPlace}</TableCell>
                    <TableCell>{row.Reason}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell>{row.UtilizationDate}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "center",
                        margin: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleDisplayCompOff(row.EntryID)}
                      >
                        <RemoveRedEyeOutlined />
                      </IconButton>
                      <IconButton
                        disabled={row.IsEditable === "False"}
                        color="primary"
                        onClick={() => handleEditCompOff(row.EntryID)}
                      >
                        <EditOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDialogTitle(
                            `Delete Comp-Off (ID: ${row.EntryID})`
                          );
                          setCompOffId(row.EntryID);
                          setDialogVariant("deleteCompOff");
                          setCompOffEntryId(row.EntryID);
                          setShowDialog(true);
                        }}
                        color="error"
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41.5 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={registerData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default CompOff;

import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import {
  deleteCompOff,
  deleteMissPunch,
  handleAuth,
  saveCompOff,
  saveLeave,
  saveMissPunch,
  updateCompOff,
  updateLeave,
  updateMissPunch,
} from "./api";
import { MissPunchFormData } from "../types/missPunch";
import { CompOffFormData } from "../types/compOff";
import { LeaveSubmitFormData } from "../types/leave";

export function useLoginMutation() {
  return useMutation({
    mutationFn: handleAuth,
    onSuccess: async () => {},
    onError: (_: Error) => {},
  });
}

export function useSaveCompOffMutation() {
  return useMutation({
    mutationFn: (data: CompOffFormData) => saveCompOff(data),
    onSuccess: async () => {
      toast("CompOff Saved!");
    },
    onError: async () => {
      toast("Error Saving CompOff");
    },
  });
}

export function useUpdateCompOffMutation() {
  return useMutation({
    mutationFn: (data: CompOffFormData) => updateCompOff(data),
    onSuccess: async () => {
      toast("CompOff Updated!");
    },
    onError: async () => {
      toast("Error Updating CompOff");
    },
  });
}

export function useDeleteCompOffMutation() {
  return useMutation({
    mutationFn: (entryId: string) => deleteCompOff(entryId),
    onSuccess: async () => {
      toast("CompOff Deleted!");
    },
    onError: async () => {
      toast("Error Deleting CompOff");
    },
  });
}

export function useSaveMissPunchMutation() {
  return useMutation({
    mutationFn: (data: MissPunchFormData) => saveMissPunch(data),
    onSuccess: async () => {
      toast("MissPunch Saved!");
    },
    onError: async () => {
      toast("Error Saving MissPunch");
    },
  });
}

export function useUpdateMissPunchMutation() {
  return useMutation({
    mutationFn: (data: MissPunchFormData) => updateMissPunch(data),
    onSuccess: async () => {
      toast("MissPunch Updated!");
    },
    onError: async () => {
      toast("Error Updating MissPunch");
    },
  });
}

export function useDeleteMissPunchMutation() {
  return useMutation({
    mutationFn: (entryId: string) => deleteMissPunch(entryId),
    onSuccess: async () => {
      toast("MissPunch Deleted!");
    },
    onError: async () => {
      toast("Error Deleting MissPunch");
    },
  });
}

export function useSaveLeaveMutation() {
  return useMutation({
    mutationFn: (data: LeaveSubmitFormData) => saveLeave(data),
    onSuccess: async () => {
      toast("CompOff Saved!");
    },
    onError: async () => {
      toast("Error Saving CompOff");
    },
  });
}

export function useUpdateLeaveMutation() {
  return useMutation({
    mutationFn: (data: LeaveSubmitFormData) => updateLeave(data),
    onSuccess: async () => {
      toast("CompOff Updated!");
    },
    onError: async () => {
      toast("Error Updating CompOff");
    },
  });
}
